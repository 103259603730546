import { AgGridReact } from "ag-grid-react";
import DeleteButton from "components/Customized/DeleteButton";
import { useMaterialUIController } from "context";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import BaseLayout from "components/Customized/BaseLayout";
import dayjs from "dayjs";
import useStockInput from "./hooks/useStockInput";
import { StockInputViewModel } from "models/stockInputViewModel";
import StockInputTable from "./components/StockInputTable";

const StockInput = () => {
  const { stockInputs, createStockInputs, deleteStockInputs, subjects, getSubjectByYear, currentYearTerms} =
    useStockInput();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const handleSave = (payload: StockInputViewModel[]) => {
    createStockInputs(payload);
  };

  const columns = [
    {
      field: "year",
      headerName: "Year",
      width: 100,
    },
    {
      field: "term",
      headerName: "Term",
      width: 100,
    },
    {
      field: "date",
      headerName: "Date",
      width: 150,
      valueFormatter: (e) => {
        return dayjs(e.date).format("YYYY-MM-DD HH:mm");
      },
    },
    {
      field: "createdBy",
      headerName: "Created By",
      type: "number",
      width: 150,
    },
    {
      field: "grade",
      headerName: "Grade",
      type: "number",
      width: 110,
    },
    {
      field: "subjectId",
      headerName: "Subject",
      type: "number",
      width: 150,
      cellRenderer: (e) => {
        return e.data.subjectName;
      },
    },
    {
      field: "copy",
      headerName: "Copy",
      type: "number",
      width: 110,
    },
    {
      field: "note",
      headerName: "Note",
      type: "number",
      width: 300,
    },
    {
      cellRenderer: DeleteButton,
      cellRendererParams: {
        confirmTitle: `Are you sure you want to delete this record?`,
        onClick: ({ id }) => deleteStockInputs(id),
      },
      cellStyle: { justifyContent: "center" },
      maxWidth: 80,
    },
  ];

  return (
    <BaseLayout>
      <Card sx={{ overflow: "visible", mt: 2 }}>
        <StockInputTable
          subjects={subjects}
          currentYearTerms = {currentYearTerms}
          getSubjectByYear={getSubjectByYear}
          onSave={handleSave}
        />
      </Card>

      <Card sx={{ overflow: "visible", mt: 2 }}>
        <MDBox p={3}>
          <MDTypography variant="h5">History</MDTypography>
        </MDBox>

        <MDBox className={darkMode ? "ag-theme-alpine-dark" : "ag-theme-alpine"} pb={3} px={3}>
          <AgGridReact rowData={stockInputs} columnDefs={columns} domLayout="autoHeight" />
        </MDBox>
      </Card>
    </BaseLayout>
  );
};

export default StockInput;

const PAGES = {
  // not auth
  signIn: "/sign-in",
  signUp: "/sign-up",
  createOrder: "/create-order",
  stockInput: "/stock-input",
  deliveryCalendar: "/delivery-calendar",
  booking: "/booking/:groupId",
  orderGroups: "/order-groups",
  stockOverview: "/stock-overview",
  deliveryForm:"/delivery-form/:id",
  qrDeliveryForm:"/qr-delivery-form",
};

export default PAGES;

export const Terms = [
  { value: "summer", name: "Summer" },
  { value: "01", name: "01" },
  { value: "au", name: "Autumn" },
  { value: "02", name: "02" },
  { value: "win", name: "Winter" },
  { value: "03", name: "03" },
  { value: "spring", name: "Spring" },
  { value: "04", name: "04" },
];

export const Branch = [
  { branchCode: "43", branchName: "Ashfield" },
  { branchCode: "5", branchName: "Bankstown" },
  { branchCode: "12", branchName: "Baulkham Hills" },
  { branchCode: "33", branchName: "Blacktown" },
  { branchCode: "41", branchName: "Cabramatta" },
  { branchCode: "13", branchName: "Campsie" },
  { branchCode: "35", branchName: "Castle Hill" },
  { branchCode: "2", branchName: "Chatswood" },
  { branchCode: "34", branchName: "Eastwood" },
  { branchCode: "3", branchName: "Epping" },
  { branchCode: "11", branchName: "Fairfield" },
  { branchCode: "58", branchName: "Gordon" },
  { branchCode: "60", branchName: "Gosford" },
  { branchCode: "17", branchName: "Hornsby" },
  { branchCode: "16", branchName: "Hurstville" },
  { branchCode: "6", branchName: "Liverpool" },
  { branchCode: "8", branchName: "Parramatta" },
  { branchCode: "45", branchName: "Pennant Hills" },
  { branchCode: "47", branchName: "Penrith" },
  { branchCode: "1", branchName: "Randwick" },
  { branchCode: "61", branchName: "Ryde" },
  { branchCode: "4", branchName: "Strathfield" },
  { branchCode: "98", branchName: "Vietnam" },
  { branchCode: "49", branchName: "Adelaide SA" },
  { branchCode: "57", branchName: "Burwood VIC" },
  { branchCode: "59", branchName: "Oakleigh VIC" },
];

export function GetBranchName(branchCode) {
  let isAny = Branch.find((m) => m.branchCode.includes(branchCode));
  return isAny.branchName;
}

export function TermHelper(term) {
  let isAny = Terms.find((m) => term.includes(m.value) || m.name.includes(term));
  return isAny.value;
}

export function YearHelper(year) {
  let years = [];
  for (let index = year + 1; index > year - 4; index--) {
    years.push(index);
  }
  return years;
}

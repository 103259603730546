import * as Yup from "yup";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import MDButton from "components/MDButton";
import MDDatePicker from "components/MDDatePicker";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import { TimeSlotViewModel } from "models/timeSlotViewModel";
import dayjs from "dayjs";
import { Branch } from "constants/term";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

type Props = {
  data: TimeSlotViewModel;
  selectedId: number | null;
  open: boolean;
  onClose: () => void;
  onSubmit: (payload: TimeSlotViewModel) => void;
  onDelete: (id: number) => void;
};

const TimeSlotSchema = Yup.object().shape({
  startTime: Yup.string().required("Required"),
  endTime: Yup.string().required("Required"),
  location: Yup.string().required("Required"),
});

const AvailableDeliverySlotDialog = (props: Props) => {
  const { selectedId, open, onClose, onDelete, onSubmit, data } = props;

  return (
    <BootstrapDialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Available Delivery Slot
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Formik
          enableReinitialize
          initialValues={data as TimeSlotViewModel}
          validationSchema={TimeSlotSchema}
          onSubmit={(values) => {
            const data = {
              ...values,
              startTime: dayjs(values.startTime).format("YYYY-MM-DDTHH:mm:ss"),
              endTime: dayjs(values.endTime).format("YYYY-MM-DDTHH:mm:ss"),
            };

            onSubmit(data);
            onClose();
          }}
          validateOnChange={false}
        >
          {({ values, errors, handleSubmit, setFieldValue }) => {
            return (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <MDDatePicker
                    value={values.startTime}
                    onChange={([value]) => {
                      setFieldValue("startTime", value);
                    }}
                    label="Start time"
                    options={{
                      enableTime: true,
                    }}
                    input={{
                      label: "Start time",
                      value: values.startTime
                        ? dayjs(values.startTime).format("YYYY-MM-DD HH:mm")
                        : "",
                      fullWidth: true,
                      variant: "standard",
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <MDDatePicker
                    value={values.endTime}
                    onChange={([value]) => {
                      setFieldValue("endTime", value);
                    }}
                    label="End time"
                    options={{
                      enableTime: true,
                    }}
                    input={{
                      label: "End time",
                      value: values.endTime ? dayjs(values.endTime).format("YYYY-MM-DD HH:mm") : "",
                      fullWidth: true,
                      variant: "standard",
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl error={!!errors.location} variant="standard" fullWidth>
                    <InputLabel id="location-label">Location</InputLabel>
                    <Select
                      labelId="location-label"
                      id="location"
                      value={values.location}
                      onChange={(e) => {
                        setFieldValue("location", e.target.value);
                      }}
                      label="Location"
                      sx={{
                        height: 29,
                      }}
                    >
                      {Branch.map((e) => {
                        return (
                          <MenuItem key={e.branchCode} value={e.branchName}>
                            {e.branchName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                  item
                  xs={12}
                >
                  {!selectedId && (
                    <MDButton sx={{ mr: 2 }} onClick={onClose} variant="gradient" color="secondary">
                      Cancel
                    </MDButton>
                  )}
                  {selectedId && (
                    <MDButton
                      sx={{ mr: 2 }}
                      onClick={() => {
                        onDelete(selectedId);
                        onClose();
                      }}
                      variant="gradient"
                      color="error"
                    >
                      Delete
                    </MDButton>
                  )}
                  <MDButton onClick={handleSubmit} variant="gradient" color="info">
                    Save
                  </MDButton>
                </Grid>
              </Grid>
            );
          }}
        </Formik>
      </DialogContent>
    </BootstrapDialog>
  );
};

export default AvailableDeliverySlotDialog;

/** 
  All of the routes for the Otis Admin PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

import Icon from "@mui/material/Icon";
import PAGES from "./pages.ts";
import CreateOrder from "features/create-order/createOrder.tsx";
import StockInput from "features/stock-input/stockInput.tsx";
import DeliveryCalendar from "features/delivery-calendar/deliveryCalendar.tsx";
import Booking from "features/booking/booking.tsx";
import OrderGroups from "features/order-groups/orderGroups.tsx";
import StockOverview from "features/stock-overview/stockOverview.tsx";
import DeliveryForm from "features/delivery-form/deliveryForm.tsx";

const routes = [
  {
    type: "tab",
    name: "Create Order",
    key: "create-order",
    route: PAGES.createOrder ,
    icon: <Icon fontSize="medium">people</Icon>,
    component: <CreateOrder />,
  },
  {
    type: "tab",
    name: "Stock input",
    key: "stock-input",
    route: PAGES.stockInput,
    icon: <Icon fontSize="medium">people</Icon>,
    component: <StockInput />,
  },
  {
    type: "tab",
    name: "Stock overview",
    key: "stock-overview",
    route: PAGES.stockOverview,
    icon: <Icon fontSize="medium">people</Icon>,
    component: <StockOverview />,
  },
  {
    type: "page",
    key: "create-order",
    route: `${PAGES.createOrder}`,
    components: <CreateOrder />,
  },
  {
    type: "tab",
    key: "delivery-calendar",
    name: "Delivery calendar",
    route: PAGES.deliveryCalendar,
    icon: <Icon fontSize="medium">people</Icon>,
    component: <DeliveryCalendar />,
  },
  {
    type: "tab",
    key: "booking",
    name: "Booking",
    route: PAGES.booking,
    icon: <Icon fontSize="medium">people</Icon>,
    component: <Booking />,
  },
  {
    type: "tab",
    key: "order-groups",
    name: "Order groups",
    route: PAGES.orderGroups,
    icon: <Icon fontSize="medium">people</Icon>,
    component: <OrderGroups />,
  },
  {
    type: "tab",
    key:"delivery-form",
    name: "Delivery Form",
    route: PAGES.deliveryForm,
    icon: <Icon fontSize="medium">people</Icon>,
    component: <DeliveryForm />,
  },
  // logout
  {
    type: "logout",
    key: "logout",
    name: "Log out",
    icon: <Icon fontSize="medium">logout</Icon>,
  },
];

export default routes;

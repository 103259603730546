import { useMaterialUIController } from "context";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import BaseLayout from "components/Customized/BaseLayout";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import StockOverviewTable from "./components/StockOverviewTable";
import useStockOverview from "./hooks/useStockOverview";
import {Branch} from 'constants/term'

const StockOverview = () => {
    const { stockOverviews, setBranchCode} = useStockOverview();
    const handleBranchChange = (e) => {
      setBranchCode(e.target.value);
    };
    
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    return (
        <BaseLayout>
            <Card sx={{ overflow: "visible", mt: 2 }}>
                <MDBox className={darkMode ? "ag-theme-alpine-dark" : "ag-theme-alpine"} pb={3} px={3}>
                    <MDTypography pt={5} pb={3} variant="h5">Stock Overview</MDTypography>
                    <FormControl sx={{ width: '300px' }} variant="outlined">
                        <InputLabel id="branch-label">Select Branch</InputLabel>
                        <Select
                            labelId="branch-label"
                            id="branch"
                            label="Select Branch"
                            onChange={handleBranchChange}
                            sx={{
                                height: 44,
                            }}
                        >
                            {Branch.map((e) => {
                                return (
                                    <MenuItem key={e.branchCode} value={e.branchCode}>
                                        {e.branchName}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <StockOverviewTable stockOverviews = {stockOverviews} />
                </MDBox>
            </Card>
        </BaseLayout>
    );
};

export default StockOverview;

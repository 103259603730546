import dayjs from "dayjs";
import { useEffect, useState } from "react";
import BookingService from "./booking.service";
import { TimeSlot } from "models/timeSlot";
// import { PickupTimeBookingViewModal } from "models/pickupTimeBookingViewModal";
import parseJwt from "utils/parseJwt";
import { toast } from "react-toastify";
import { useParams, useSearchParams } from "react-router-dom";
import { NCBookOrderViewModel } from "models/bookOrder";

export type TimeOption = {
  timeSlotId: number;
  startTime: string;
  endTime: string;
};

const useBooking = () => {
  const [searchParams] = useSearchParams();

  const token = searchParams.get("token");
  const { sub: branchCode, given_name: branchName } = parseJwt(token);

  const { groupId } = useParams();
  const [currentWeek, setCurrentWeek] = useState<{
    start: string;
    end: string;
  }>(() => {
    const today = new Date();
    const currentDayOfWeek = today.getDay();
    const startDay = new Date(today);
    startDay.setDate(today.getDate() - currentDayOfWeek + (currentDayOfWeek === 0 ? -6 : 1));
    const endDay = new Date(startDay);
    endDay.setDate(startDay.getDate() + 6);

    return {
      start: startDay.toISOString(),
      end: endDay.toISOString(),
    };
  });
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [timeSlots, setTimeSlots] = useState<
    Array<{
      location: string;
      startTime: string;
      endTime: string;
    }>
  >([]);

  const [orderGroup, setOrderGroup] = useState<NCBookOrderViewModel[]>([]);
  const [timeOptions, setTimeOptions] = useState<TimeOption[]>([]);
  const [selectedTime, setSelectedTime] = useState<TimeOption | null>(null);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const orderGroup = await BookingService.getBookOrdersByGroupId(+groupId) as any;
        setOrderGroup(orderGroup.data.data);
        console.log('orderGroup.data',orderGroup.data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [groupId]);
  const getListBooking = async (dateTime: string, branchCode: string, groupId: string) => {
    try {
      const res = await BookingService.get({ dateTime, branchCode, groupId });
      const data = res.data.data;
      const options: TimeOption[] = [];

      setTimeSlots(
        data.map((e) => {
          return {
            location: e.location,
            endTime: e.endTime,
            startTime: e.startTime,
          };
        })
      );

      data.forEach((timeSlot: TimeSlot) => {
        const startTime = new Date(timeSlot.startTime);
        const endTime = new Date(timeSlot.endTime);

        for (let i = 0; i < endTime.getTime() - startTime.getTime(); i += 1800000) {
          const newOption = {
            timeSlotId: timeSlot.id,
            startTime: new Date(startTime.getTime() + i).toISOString(),
            endTime: new Date(startTime.getTime() + i + 1800000).toISOString(),
          };

          if (
            !timeSlot.pickupTimeBookings.find((e) => {
              return (
                newOption.startTime == new Date(e.startTime).toISOString() &&
                newOption.endTime == new Date(e.endTime).toISOString()
              );
            })
          ) {
            options.push(newOption);
          }
        }
      });

      setTimeOptions(
        options.sort((a, b) => {
          return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleBooking = async () => {
    try {
      const res = await BookingService.create({
        ...selectedTime,
        startTime: dayjs(selectedTime.startTime).format("YYYY-MM-DDTHH:mm:ss"),
        endTime: dayjs(selectedTime.endTime).format("YYYY-MM-DDTHH:mm:ss"),
        branchCode,
        branchName,
        orderGroupId: groupId,
      } as any);

      getListBooking(selectedDate, branchCode, groupId);
      setSelectedTime(null);

      if (res.data.status) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Delivery scheduled failure");
    }
  };

  const handleMoveToNextWeek = () => {
    const endOfCurrentWeek = new Date(currentWeek.end);
    endOfCurrentWeek.setDate(endOfCurrentWeek.getDate() + 1);

    const start = endOfCurrentWeek.toISOString();
    endOfCurrentWeek.setDate(endOfCurrentWeek.getDate() + 6);
    const end = endOfCurrentWeek.toISOString();

    setCurrentWeek({
      start,
      end,
    });
  };

  const handleMoveToPrevWeek = () => {
    const startOfCurrentWeek = new Date(currentWeek.start);
    startOfCurrentWeek.setDate(startOfCurrentWeek.getDate() - 1);

    const end = startOfCurrentWeek.toISOString();
    startOfCurrentWeek.setDate(startOfCurrentWeek.getDate() - 6);
    const start = startOfCurrentWeek.toISOString();

    setCurrentWeek({
      start,
      end,
    });
  };

  const handleSelectDate = (date: string) => {
    setSelectedDate(date);
    setSelectedTime(null);

    getListBooking(date, branchCode, groupId);
  };

  const handleSelectTime = (date: TimeOption) => {
    setSelectedTime(date);
  };

  const dateOptions = [];

  for (let i = 0; i < 7; i++) {
    const date = new Date(currentWeek.start);
    date.setDate(date.getDate() + i);

    dateOptions.push({
      value: date.toISOString(),
      label: dayjs(date).format("ddd MM/DD"),
    });
  }

  return {
    currentWeek,
    timeSlots,
    timeOptions,
    handleMoveToNextWeek,
    handleMoveToPrevWeek,
    handleBooking,
    selectedDate,
    handleSelectDate,
    selectedTime,
    handleSelectTime,
    dateOptions,
    orderGroup
  };
};

export default useBooking;

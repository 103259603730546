import { useEffect, useRef, useState } from "react";
import { TimeSlotResponse } from "models/timeSlotResponse";
import DeliveryCalendarService from "./deliveryCalendar.service";
import { TimeSlotViewModel } from "models/timeSlotViewModel";
import dayjs from "dayjs";

const useDeliveryCalendar = () => {
  const calendarRef = useRef<any>();
  const [timeSlots, setTimeSlots] = useState<TimeSlotResponse[]>([]);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [queryParams, setQueryParams] = useState<{
    startTime: string;
    endTime: string;
  }>(() => {
    const today = new Date();
    const currentDayOfWeek = today.getDay();
    const startDay = new Date(today);
    startDay.setDate(today.getDate() - currentDayOfWeek + (currentDayOfWeek === 0 ? -6 : 1));
    const endDay = new Date(startDay);
    endDay.setDate(startDay.getDate() + 6);

    return {
      startTime: startDay.toISOString(),
      endTime: endDay.toISOString(),
    };
  });

  useEffect(() => {
    getTimeSlots(queryParams);
  }, []);

  useEffect(() => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();

      const onViewRender = (info) => {
        if (info.view.type === "timeGridWeek") {
          const startTime = info.view.activeStart.toISOString();
          const endTime = info.view.activeEnd.toISOString();

          getTimeSlots({
            startTime,
            endTime,
          });
          setQueryParams({
            startTime,
            endTime,
          });
        }
      };

      calendarApi.on("datesSet", onViewRender);

      return () => {
        calendarApi.off("datesSet", onViewRender);
      };
    }
  }, []);

  const events = timeSlots.map((e) => {
    return {
      id: e.id.toString(),
      title: e.location,
      start: e.startTime,
      end: e.endTime,
      extendedProps: {
        pickupTimeBookings: e.pickupTimeBookings,
      },
    };
  });

  const genColors = () => {
    const arr = [
      {
        borderColor: "rgb(255, 194, 39)",
        backgroundColor: "rgb(255, 241, 204)",
      },
      {
        borderColor: "rgb(72, 231, 126)",
        backgroundColor: "rgb(195, 255, 216)",
      },
      {
        borderColor: "rgb(255, 56, 104)",
        backgroundColor: "rgb(255, 227, 234)",
      },
    ];

    return arr[Math.floor(Math.random() * arr.length)];
  };

  const handleEventContent = (e) => {
    return e.event.extendedProps.pickupTimeBookings.map((pickupTime) => {
      return (
        <div
          style={{
            border: "2px solid",
            borderLeft: "4px solid",
            paddingLeft: "3px",
            marginBottom: "2px",
            color: "#000",
            ...genColors(),
          }}
          key={pickupTime.id}
        >
          <ul>
            <li>
              {dayjs(pickupTime.startTime).format("HH:mm")} -{" "}
              {dayjs(pickupTime.endTime).format("HH:mm")}
            </li>
            <li>Location: {pickupTime.branchName}</li>
          </ul>
        </div>
      );
    });
  };

  const getTimeSlots = async (queryParams: { startTime: string; endTime: string }) => {
    try {
      const res = await DeliveryCalendarService.getAll({
        startTime: queryParams.startTime,
        endTime: queryParams.endTime,
      });

      setTimeSlots(res.data.data);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const createTimeSlots = async (payload: TimeSlotViewModel) => {
    try {
      await DeliveryCalendarService.create(payload);

      getTimeSlots(queryParams);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const updateTimeSlots = async (payload: TimeSlotViewModel) => {
    try {
      await DeliveryCalendarService.update(payload);

      getTimeSlots(queryParams);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const deleteTimeSlots = async (id: number) => {
    try {
      await DeliveryCalendarService.delete(id);

      getTimeSlots(queryParams);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  return {
    calendarRef,
    events,
    timeSlots,
    createTimeSlots,
    updateTimeSlots,
    deleteTimeSlots,
    selectedId,
    setSelectedId,
    handleEventContent,
  };
};

export default useDeliveryCalendar;

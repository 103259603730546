import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import BaseLayout from "components/Customized/BaseLayout";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { styled } from "@mui/system";
import TimeAvailable from "./components/TimeAvailable";
import useBooking from "./useBooking";
import { Grid } from "@mui/material";
import dayjs from "dayjs";
import TimeSlotInfo from "./components/TimeSlotInfo";
import OrderDetail from "./components/OrderDetail";

const Heading = styled("h3")({
  textAlign: "center",
  color: "#388CFF",
  fontSize: "26px",
  fontWeight: 600,
});

const Container = styled("div")({
  backgroundColor: "white",
  height: "500px",
});

const Booking = () => {
  const {
    timeSlots,
    handleSelectDate,
    timeOptions,
    selectedTime,
    handleBooking,
    handleSelectTime,
    orderGroup
  } = useBooking();

  return (
    <BaseLayout>
      <MDBox mb={2}>
        <Heading>Booking</Heading>
      </MDBox>

      <Container>
        <Grid container>
          <Grid item xs={5}>
            <OrderDetail data={orderGroup}/>
          </Grid>

          <Grid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateCalendar
                onChange={(e) => {
                  handleSelectDate(dayjs(e as any).format("YYYY-MM-DD"));
                }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={3}>
            <TimeAvailable timeInfo={timeSlots} options={timeOptions} value={selectedTime} onSelect={handleSelectTime} />
          </Grid>
        </Grid>
      </Container>

      <MDBox textAlign="center" mt={2}>
        <MDButton disabled={!selectedTime} onClick={handleBooking} size="large" color="info">
          Book
        </MDButton>
      </MDBox>
    </BaseLayout>
  );
};

export default Booking;

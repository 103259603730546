import BaseLayout from "components/Customized/BaseLayout";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import AvailableDeliverySlotDialog from "./components/AvailableDeleverySlotDialog";
import useDeliveryCalendar from "./useDeliveryCalendar";
import { TimeSlotViewModel } from "models/timeSlotViewModel";
import useModal from "hooks/useModal";

const DeliveryCalendar = () => {
  const { open, toggle } = useModal();
  const {
    calendarRef,
    events,
    timeSlots,
    createTimeSlots,
    updateTimeSlots,
    deleteTimeSlots,
    selectedId,
    setSelectedId,
    handleEventContent,
  } = useDeliveryCalendar();

  const handleEventClick = (clickInfo) => {
    setSelectedId(+clickInfo.event.id);

    toggle();
  };

  return (
    <BaseLayout>
      <AvailableDeliverySlotDialog
        key={selectedId}
        selectedId={selectedId}
        open={open}
        onClose={toggle}
        onSubmit={selectedId ? updateTimeSlots : createTimeSlots}
        onDelete={deleteTimeSlots}
        data={
          timeSlots.find((e) => e.id === selectedId) ||
          ({
            startTime: "",
            endTime: "",
            location: "",
          } as TimeSlotViewModel)
        }
      />

      <MDBox mb={1}>
        <MDButton
          onClick={() => {
            toggle();
            setSelectedId(null);
          }}
          variant="gradient"
          color="info"
        >
          Add Delivery Slot
        </MDButton>
      </MDBox>

      <Card sx={{ overflow: "visible" }}>
        <MDBox p={3}>
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            timeZone="local"
            initialView="timeGridWeek"
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "timeGridWeek",
            }}
            editable={false}
            selectable={false}
            selectMirror={false}
            dayMaxEvents={false}
            events={events}
            eventClick={handleEventClick}
            eventContent={handleEventContent}
            slotDuration="00:15:00"
            slotLabelInterval={{ minutes: 15 }}
          />
        </MDBox>
      </Card>
    </BaseLayout>
  );
};

export default DeliveryCalendar;

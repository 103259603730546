import { Checkbox, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { NCBookOrder } from "models/nCBookOrder";

const Container = styled("div")({
  border: "1px solid #9EC7FF",
  borderRadius: "4px",
  padding: "5px",
  width: "100%",
  display: "flex",
  alignItems: "center",
  gap: "10px",
  cursor: "pointer",
});

const Label = styled("div")({
  backgroundColor: "#F3F3F3",
  borderRadius: "4px",
  color: "#388CFF",
  display: "inline-block",
  padding: "4px 14px",
  fontSize: "14px",
});

const Flex = styled("div")({
  marginLeft: "10px",
  display: "flex",
  gap: "40px",
});

type Props = {
  selected: boolean;
  data: NCBookOrder;
  onSelect: (id: number) => void;
};

const OrderItem = ({ selected, data, onSelect }: Props) => {
  return (
    <Container
      onClick={() => {
        onSelect(data.orderDetailID);
      }}
    >
      <Checkbox
        checked={selected}
        onChange={() => {
          onSelect(data.orderDetailID);
        }}
      />

      <Label>Order</Label>

      <Flex>
        {/* <Typography fontSize={15}>
          <strong>Date:</strong> 2023/12/08
        </Typography>
        <Typography fontSize={15}>
          <strong>Time:</strong> 08:30
        </Typography> */}
        <Typography fontSize={15}>
          <strong>Grade:</strong> {data.grade}
        </Typography>
        <Typography fontSize={15}>
          <strong>Subject:</strong> {data.productName}
        </Typography>
        <Typography fontSize={15}>
          <strong>Copy:</strong> {data.quantity}
        </Typography>
      </Flex>
    </Container>
  );
};

export default OrderItem;

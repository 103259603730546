import { AxiosResponse } from "axios";
import { AuthenticationResponseViewModel } from "models/authenticationResponseViewModel";
import api from "services/api";

const apis = {
  login: "Authentications/Login",
};

const API = {
  login: (
    email: string,
    password: string
  ): Promise<AxiosResponse<AuthenticationResponseViewModel>> =>
    api.post(apis.login, { email, password }),
};

export default API;

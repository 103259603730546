import { StockInputViewModel } from "models/stockInputViewModel";
import api from "services/api";

const StockInputService = {
  getAll: ({ pageSize, currentPage }: { pageSize: number; currentPage: number }) => {
    return api.get(`/StockInputs?currentPage=${currentPage}&pageSize=${pageSize}`);
  },

  create: (payload: StockInputViewModel[]) => {
    return api.post("/StockInputs", payload);
  },

  delete: (id: number) => {
    return api.delete("/StockInputs/" + id);
  },

  getSubjectByYear: (year: number, term: string, grade: number) => {
    return api.get(`/Subjects?year=${year}&term=${term}&grade=${grade}`);
  },

  getCurrentYearTerm: () => {
    return api.get("/bookorder/get-current-year-term");
  },
};

export default StockInputService;

import { PickupTimeBookingViewModal } from "models/pickupTimeBookingViewModal";
import api from "services/api";

const BookingService = {
  get: ({ dateTime, branchCode, groupId}: { dateTime: string; branchCode: string; groupId:string,}) => {
    return api.get(`/Bookings/${groupId}?branchCode=${branchCode}&dateTime=${dateTime}`);
  },
  getBookOrdersByGroupId: (id:number) =>{
    return api.get(`/BookOrder/GetBookOrdersByGroupId/${id}`);
  },
  create: (payload: PickupTimeBookingViewModal) => {
    return api.post("/Bookings", payload);
  },

  delete: (id: number) => {
    return api.delete("/Bookings/" + id);
  },
};

export default BookingService;

import API from "./createOrder.api";
import { store } from "stores";
import { StatesFilterRequest } from "models/statesFilterRequest";
import { setLoading } from "stores/reducers/common.reducer";
import { StateRequest } from "models/stateRequest";
import { BookRequest } from "models/bookRequest";
import { SubjectRequest } from "models/subjectRequest";
import { YearRequest } from "models/yearRequest";

export const getAllStates = async (filters: StatesFilterRequest, success?: any, errors?: any, isLoading?: boolean) => {
  const { dispatch } = store;
  if(isLoading) dispatch(setLoading(true));
  try {
    const response = await API.getStates(filters);
    if (response.data) {
      if (success) success(response.data);
    }
  } catch (error) {
    if (errors) errors(error.message);
  }
  if(isLoading) dispatch(setLoading(false));
};

export const createStateRequest = async (data: StateRequest, success?: any, errors?: any) => {
  const { dispatch } = store;

  dispatch(setLoading(true));
  try {
    const response = await API.createState(data);
    if (success) success(response.data);
  } catch (error) {
    errors?.(error.message);
  }
  dispatch(setLoading(false));
};

export const updateStateRequest = async (id: number, data: StateRequest, success?: any, errors?: any) => {
  const { dispatch } = store;

  dispatch(setLoading(true));
  try {
    const response = await API.updateState(id, data);
    if (success) success(response.data);
  } catch (error) {
    errors?.(error.message);
  }
  dispatch(setLoading(false));
};

export const deleteStateRequest = async (id: number, success?: any, errors?: any) => {
  const { dispatch } = store;

  dispatch(setLoading(true));
  try {
    const response = await API.deleteState(id);
    if (success) success(response.data);
  } catch (error) {
    errors?.(error.message);
  }
  dispatch(setLoading(false));
};

export const createYearRequest = async (data: YearRequest, success?: any, errors?: any) => {
  const { dispatch } = store;

  dispatch(setLoading(true));
  try {
    const response = await API.createYear(data);
    if (success) success(response.data);
  } catch (error) {
    errors?.(error.message);
  }
  dispatch(setLoading(false));
};

export const updateYearRequest = async (id: number, data: YearRequest, success?: any, errors?: any) => {
  const { dispatch } = store;

  dispatch(setLoading(true));
  try {
    const response = await API.updateYear(id, data);
    if (success) success(response.data);
  } catch (error) {
    errors?.(error.message);
  }
  dispatch(setLoading(false));
};

export const deleteYearRequest = async (id: number, success?: any, errors?: any) => {
  const { dispatch } = store;

  dispatch(setLoading(true));
  try {
    const response = await API.deleteYear(id);
    if (success) success(response.data);
  } catch (error) {
    errors?.(error.message);
  }
  dispatch(setLoading(false));
};

export const createSubjectRequest = async (data: SubjectRequest, success?: any, errors?: any) => {
  const { dispatch } = store;

  dispatch(setLoading(true));
  try {
    const response = await API.createSubject(data);
    if (success) success(response.data);
  } catch (error) {
    errors?.(error.message);
  }
  dispatch(setLoading(false));
};

export const updateSubjectRequest = async (id: number, data: SubjectRequest, success?: any, errors?: any) => {
  const { dispatch } = store;

  dispatch(setLoading(true));
  try {
    const response = await API.updateSubject(id, data);
    if (success) success(response.data);
  } catch (error) {
    errors?.(error.message);
  }
  dispatch(setLoading(false));
};

export const deleteSubjectRequest = async (id: number, success?: any, errors?: any) => {
  const { dispatch } = store;

  dispatch(setLoading(true));
  try {
    const response = await API.deleteSubject(id);
    if (success) success(response.data);
  } catch (error) {
    errors?.(error.message);
  }
  dispatch(setLoading(false));
};

export const createBookRequest = async (data: BookRequest, success?: any, errors?: any) => {
  const { dispatch } = store;

  dispatch(setLoading(true));
  try {
    const response = await API.createBook(data);
    if (success) success(response.data);
  } catch (error) {
    errors?.(error.message);
  }
  dispatch(setLoading(false));
};

export const updateBookRequest = async (id: number, data: BookRequest, success?: any, errors?: any) => {
  const { dispatch } = store;

  dispatch(setLoading(true));
  try {
    const response = await API.updateBook(id, data);
    if (success) success(response.data);
  } catch (error) {
    errors?.(error.message);
  }
  dispatch(setLoading(false));
};

export const deleteBookRequest = async (id: number, success?: any, errors?: any) => {
  const { dispatch } = store;

  dispatch(setLoading(true));
  try {
    const response = await API.deleteBook(id);
    if (success) success(response.data);
  } catch (error) {
    errors?.(error.message);
  }
  dispatch(setLoading(false));
};
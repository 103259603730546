import PAGES from "navigation/pages"
import { useEffect } from "react"
import { useSelector } from "react-redux"

import { styled } from "@mui/system";

import BaseLayout from "components/Customized/BaseLayout";

import useDeliveryForm from "./useDeliveryForm"

const QrCodeDeliveryForm = () => {
   const {handleClearBranch} = useDeliveryForm();

    return(
        <BaseLayout>
            
            
        </BaseLayout>
    );
}

export default QrCodeDeliveryForm
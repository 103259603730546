import { useMaterialUIController } from "context";
import { styled } from "@mui/system";
import MDBox from "components/MDBox";
import { AgGridReact } from "ag-grid-react"

const Container = styled("div")({
  padding: "30px",
  borderRight: "1px solid #e8e8e8",
  borderTop: "none",
  backgroundColor: "white",
  height: "100%",
});

type Props = {
  data: any
};

const OrderDetail = (props: Props) => {
  const { data } = props;
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const columns = [
    {
      field: "branchCode",
      headerName: "Branch code",
      width: 150,
    },
    {
      field: "grade",
      headerName: "Grade",
      width: 110,
    },
    {
      field: "productName",
      headerName: "Product name",
      type: "number",
      width: 240,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      type: "number",
      width: 110,
    },
  ];

  return (
    <Container>
      <>
        <h5>Order detail</h5>
        <MDBox mt={2} className={darkMode ? "ag-theme-alpine-dark" : "ag-theme-alpine"}>
          <AgGridReact rowData={data} columnDefs={columns} domLayout="autoHeight" />
        </MDBox>
      </>
    </Container>
  );
};

export default OrderDetail;

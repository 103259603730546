import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import OrderGroupService, { GetBookOrderQueryParams } from "./orderGroups.service";
import { BookOrderRequest } from "models/bookOrderRequest";
import {GetBranchName} from 'constants/term';

const useOrderGroup = () => {
  const [currentYearTerm, setCurrentYearTerm] = useState<any>();
  const [orders, setOrders] = useState<any>([]);
  const [orderGroups, setOrderGroups] = useState<any>([]);
  const [orderGroupStatus, setOrderGroupStatus] = useState<string>("New");
  const [term, setTerm] = useState<string>("");
  const [year, setYear] = useState<string>("");
  const [branchCode, setBranchCode] = useState<string>("");
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  useEffect(() => {
    getCurrentYearTerm();
  }, []);

  useEffect(() => {
    if (term && year && branchCode && orderGroupStatus) {
      getOrders({
        branchCode: +branchCode,
        status: orderGroupStatus,
        term: term,
        year: +year,
      });
    }
  }, [currentYearTerm, orderGroupStatus, term, year, branchCode]);

  const getCurrentYearTerm = async () => {
    try {
      const res = await OrderGroupService.getCurrentYearTerm();
      setCurrentYearTerm(res.data);

      setTerm(res.data.termName);
      setYear(res.data.year);
    } catch (error) {
      console.log(error);
    }
  };

  const getOrders = async (params: GetBookOrderQueryParams) => {
    try {
      const res = await OrderGroupService.getBookOrder(params);
      setOrders(res.data.data.bookOrders);
      setOrderGroups(res.data.data.orderGroups);
    } catch (error) {
      console.log(error);
    }
  };

  const postGroupOrder = async () => {
    try {
      const res = await OrderGroupService.groupOrder(selectedIds);

      if (res.data.data) {
        toast.success("Create group order successfully");

        getOrders({
          branchCode: +branchCode,
          status: orderGroupStatus,
          term: currentYearTerm.termName,
          year: currentYearTerm.year,
        });
      } else {
        toast.error("Create group order failure: " + res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectOrder = (id: number) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(
        selectedIds.filter((e) => {
          return e != id;
        })
      );
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const handleSetStatus = async (id: number, request: BookOrderRequest) => {
    try {
      await OrderGroupService.setStatusOrderGroup(id, request);
      getOrders({
        branchCode: +branchCode,
        status: orderGroupStatus,
        term: term,
        year: +year,
      });
      toast.success("Set status success!");
    } catch (error) {
      toast.error("Set status false!");
    }
  };

  const handleCreateDelivery = async (date: Date, id: number) => {
    try {
      await OrderGroupService.createDeliveryForm(date, id,GetBranchName(branchCode),branchCode);
      await OrderGroupService.setStatusOrderGroup(id, {
        status: 3,
        backStatus: false,
      });
      toast.success("Success");
      getOrders({
        branchCode: +branchCode,
        status: orderGroupStatus,
        term: term,
        year: +year,
      });
    } catch (error) {
      toast.error("false");
    }
  };
  return {
    orders,
    orderGroups,
    currentYearTerm,
    postGroupOrder,
    selectedIds,
    handleSelectOrder,
    handleSetStatus,
    handleCreateDelivery,
    orderGroupStatus,
    setOrderGroupStatus,
    term,
    setTerm,
    year,
    setYear,
    branchCode,
    setBranchCode,
  };
};

export default useOrderGroup;

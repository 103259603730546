import { useCallback, useEffect, useMemo, useState } from "react";
import {
  createBookRequest,
  createStateRequest,
  createSubjectRequest,
  createYearRequest,
  deleteBookRequest,
  deleteStateRequest,
  deleteSubjectRequest,
  deleteYearRequest,
  getAllStates,
  updateBookRequest,
  updateStateRequest,
  updateSubjectRequest,
  updateYearRequest,
} from "../createOrder.services";
import { StateResponse } from "models/stateResponse";
import { ObjectTypes, StateItem } from "../configs/types";
import { ResponseModel } from "models/responseModel";
import { PagedResponseDto } from "models/pagedResponseDto";
import { toast } from "react-toastify";
import { StatesFilterRequest } from "models/statesFilterRequest";
import { DEFAULT_STATE_FILTER } from "../configs/constants";
import { StateRequest } from "models/stateRequest";
import { YearRequest } from "models/yearRequest";
import { YearResponse } from "models/yearResponse";
import { BookRequest } from "models/bookRequest";
import { BookResponse } from "models/bookResponse";
import { SubjectRequest } from "models/subjectRequest";
import { SubjectResponse } from "models/subjectResponse";
import _ from "lodash";

const useCreateOrder = () => {
  const [rawData, setRawData] = useState<StateResponse[]>([]);
  const [filters, setFilters] = useState<StatesFilterRequest>(DEFAULT_STATE_FILTER);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [searchText, setSearchText] = useState<string>()

  const handelChangeTextSearch = (text: string) => {
    setSearchText(text || "")
  }

  const handleChangePage = (_: unknown, newPage: number) => {
    console.log({
      newPage
    });
    
    setFilters({
      ...filters,
      currentPage: newPage + 1,
    });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({
      ...filters,
      currentPage: 1,
      pageSize: parseInt(event.target.value, 10),
    });
  };

  const getData = useCallback((isLoading?: boolean) => {
    getAllStates(
      filters,
      (data: ResponseModel<PagedResponseDto<StateResponse>>) => {
        if (!data.status) {
          toast.error("Get data failure");
        } else {
          setRawData(data.data.items || []);
          setTotalItems(data.data.totalItems);
          if (data.data.items?.length === 0 && filters.currentPage > 1 && data.status)
            setFilters((state) => ({
              ...state,
              currentPage: state.currentPage - 1,
            }));
        }
      },
      (error: string) => {
        toast.error(error);
      },
      isLoading
    );
  }, [JSON.stringify(filters)]);

  const debounceSearch = _.debounce(() => {
    setFilters(state => ({
      ...state,
      currentPage: 1,
      search: searchText
    }))
  }, 300)

  const handleSaveItem = async (
    value: string,
    type: ObjectTypes,
    data: StateItem,
    isUpdate: boolean,
    callback?: any
  ) => {
    let errorMessage = null;
    let isSuccess = false;
    switch (type) {
      case "State":
        {
          const stateRequest: StateRequest = {
            name: value,
          };
          const onSave = isUpdate
            ? (item: StateRequest, success?: any, errors?: any) =>
                updateStateRequest(data.stateId, item, success, errors)
            : createStateRequest;
          await onSave(
            stateRequest,
            (data: ResponseModel<StateResponse>) => {
              errorMessage = !data.status ? data.message : null;
              isSuccess = !!data.status;
            },
            (error: string) => {
              errorMessage = error;
            }
          );
        }
        break;
      case "Year":
        {
          const yearRequest: YearRequest = {
            name: value,
            stateId: data.stateId,
          };
          const onSave = isUpdate
            ? (item: YearRequest, success?: any, errors?: any) =>
                updateYearRequest(data.yearId, item, success, errors)
            : createYearRequest;
          await onSave(
            yearRequest,
            (data: ResponseModel<YearResponse>) => {
              errorMessage = !data.status ? data.message : null;
              isSuccess = !!data.status;
            },
            (error: string) => {
              errorMessage = error;
            }
          );
        }
        break;
      case "Subject":
        {
          const subjectRequest: SubjectRequest = {
            name: value,
            yearId: data.yearId,
          };
          const onSave = isUpdate
            ? (item: SubjectRequest, success?: any, errors?: any) =>
                updateSubjectRequest(data.subjectId, item, success, errors)
            : createSubjectRequest;
          await onSave(
            subjectRequest,
            (data: ResponseModel<SubjectResponse>) => {
              errorMessage = !data.status ? data.message : null;
              isSuccess = !!data.status;
            },
            (error: string) => {
              errorMessage = error;
            }
          );
        }
        break;
      case "Book":
        {
          const bookRequest: BookRequest = {
            name: value,
            subjectId: data.subjectId,
          };
          const onSave = isUpdate
            ? (item: BookRequest, success?: any, errors?: any) =>
                updateBookRequest(data.bookId, item, success, errors)
            : createBookRequest;
          await onSave(
            bookRequest,
            (data: ResponseModel<BookResponse>) => {
              errorMessage = !data.status ? data.message : null;
              isSuccess = !!data.status;
            },
            (error: string) => {
              errorMessage = error;
            }
          );
        }
        break;
      default:
        break;
    }

    if (errorMessage) toast.error(errorMessage);
    if (isSuccess) {
      callback?.();
      getData();
    }
  };

  const handleDeleteItem = async (data: StateItem, type: ObjectTypes) => {
    const shouldDelete = confirm(`Do you really want to delete this ${type.toLowerCase()}?`);
    if (!shouldDelete) {
      return
    }
    let id = 0;
    let onDelete = null;
    
    switch (type) {
      case "State":
        id = data.stateId;
        onDelete = deleteStateRequest;
        break;

      case "Year":
        id = data.yearId;
        onDelete = deleteYearRequest;
        break;

      case "Subject":
        id = data.subjectId;
        onDelete = deleteSubjectRequest;
        break;

      case "Book":
        id = data.bookId;
        onDelete = deleteBookRequest;
        break;

      default:
        break;
    }

    if (!id || !onDelete) {
      toast.error("Invalid request to delete");
      return;
    }
    let errorMessage = null;
    let isSuccess = false;
    await onDelete(
      id,
      (data: ResponseModel<boolean>) => {
        errorMessage = !data.status ? data.message : null;
        isSuccess = !!data.status;
      },
      (error: string) => {
        errorMessage = error;
      }
    );

    if (errorMessage) toast.error(errorMessage);
    if (isSuccess) getData();
  };

  const convertData = () => {
    let data: StateItem[] = [];
    rawData.forEach((state) => {
      const years = state.years || [];
      let subjectData: StateItem[] = [];
      let stateRows = 1;

      if (years.length === 0)
        subjectData = [
          {
            state: state.name,
            stateId: state.id,
          },
        ];
      else {
        years.forEach((year) => {
          const bookData: StateItem[] = [];
          let yearRows = 1;
          const subjects = year.subjects || [];
          
          if (subjects.length === 0){
            bookData.push({
              state: state.name,
              stateId: state.id,
              year: year.name,
              yearId: year.id,
            });
          }
          else {
            subjects.forEach((subject) => {
              const books = subject.books || [];
              const subjectRows = books.length + 1;
              yearRows += subjectRows;

              if (books.length === 0)
                bookData.push({
                  state: state.name,
                  stateId: state.id,
                  year: year.name,
                  yearId: year.id,
                  subject: subject.name,
                  subjectId: subject.id,
                  subjectRows,
                });
              else
                books.forEach((book) => {
                  bookData.push({
                    state: state.name,
                    stateId: state.id,
                    year: year.name,
                    yearId: year.id,
                    subject: subject.name,
                    subjectId: subject.id,
                    book: book.name,
                    bookId: book.id,
                    subjectRows,
                  });
                });
            });
          }
          
          stateRows += yearRows;
          subjectData = [
            ...subjectData,
            ...bookData.map((b) => ({
              ...b,
              yearRows,
            })),
          ];
        });
      }

      data = [
        ...data,
        ...subjectData.map((b) => ({
          ...b,
          stateRows: stateRows,
        })),
      ];
    });
    return data;
  };

  const data = useMemo(() => convertData(), [JSON.stringify(rawData)]);

  console.log({
    data,
    rawData
  });
  

  useEffect(() => {
    getData();
  }, [filters.currentPage, filters.pageSize]);

  useEffect(() => {
    if(filters.search !== undefined) getData(false);
  }, [filters.search]);

  useEffect(() => {
    if(searchText !== undefined) debounceSearch()
  }, [searchText])

  return {
    data,
    filters,
    totalItems,
    searchText,
    handelChangeTextSearch,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSaveItem,
    handleDeleteItem,
  };
};
export default useCreateOrder;

import { useEffect, useState } from "react";
import { generateRandomId } from "utils/randomId";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import dayjs from "dayjs";
// import * as Yup from "yup";
import { useMaterialUIController } from "context";
import { StockInputViewModel } from "models/stockInputViewModel";
import { CurrentYearTerm } from "models/currentYearTerm";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDDatePicker from "components/MDDatePicker";
import FormField from "components/Customized/FormFiled";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { SubjectView } from "models/subjectView";
import { Terms, TermHelper, YearHelper } from "constants/term";
type Props = {
  subjects: SubjectView[];
  currentYearTerms: CurrentYearTerm;
  getSubjectByYear: (grade: number) => void;
  onSave: (payload: StockInputViewModel[]) => void;
};

// const StockSchema = Yup.object().shape({
//   year: Yup.string().required("Required"),
//   term: Yup.string().required("Required"),
//   grade: Yup.string().required("Required"),
//   subjectId: Yup.string().required("Required"),
//   copy: Yup.string().required("Required"),
//   date: Yup.string().required("Required"),
//   note: Yup.string().required("Required"),
//   createdBy: Yup.string().required("Required"),
// });

const StockInputTable = (props: Props) => {
  const { subjects, currentYearTerms, getSubjectByYear, onSave } = props;
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [currentRowEdit, setCurrentRowEdit] = useState<string | null>(null);
  const [values, setValues] = useState<StockInputViewModel | null>();

  console.log("???: ", values);
  console.log("???subjects: ", subjects);

  const [stockInputsTemp, setStockInputsTemp] = useState([]);

  const setFieldValue = (field: string, value: any) => {
    setValues((preValues) => ({
      ...preValues,
      [field]: value,
    }));
  };

  const handleSubmit = () => {
    setStockInputsTemp(
      stockInputsTemp.map((e) => {
        if (e.id === currentRowEdit) {
          return values;
        }

        return e;
      })
    );
    setCurrentRowEdit(null);
    setValues(null);
  };

  const handleSave = () => {
    console.log('stockInputsTemp',stockInputsTemp);

    onSave(
      stockInputsTemp.map((e) => {
        return {
          year: e.year,
          term: e.term,
          grade: e.grade,
          copy: e.copy,
          createdBy: e.createdBy,
          note: e.note,
          ncSubjectId: e.ncSubjectId,
          subjectName:e.subjectName,
          date: dayjs(e.date).format("YYYY-MM-DD"),
        };
      }) as StockInputViewModel[]
    );
    setStockInputsTemp([]);
  };

  const handleDelete = (id) => {
    setStockInputsTemp(
      stockInputsTemp.filter((e) => {
        return e.id !== id;
      })
    );
  };

  const handleAddNewRow = () => {
    const randomId = generateRandomId();
    const initValue = {
      id: randomId,
      year: currentYearTerms.year,
      term: TermHelper(currentYearTerms.term),
      grade: null,
      copy: null,
      createdBy: "Mark",
      note: null,
      date: null,
      ncSubjectId: null,
      subjectName : null
    };

    setStockInputsTemp([...stockInputsTemp, initValue as StockInputViewModel]);

    setCurrentRowEdit(randomId);
    setValues(initValue as StockInputViewModel);
  };

  return (
    <>
      <MDBox className={darkMode ? "ag-theme-alpine-dark" : "ag-theme-alpine"} pt={3} pb={2} px={3}>
        <MDButton onClick={handleAddNewRow} sx={{ mb: 2 }} color="info">
          New row
        </MDButton>

        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ display: "contents" }}>
            <TableRow>
              <TableCell sx={{ width: "10%" }}>Year</TableCell>
              <TableCell sx={{ width: "10%" }}>Term</TableCell>
              <TableCell sx={{ width: "10%" }}>Grade</TableCell>
              <TableCell sx={{ width: "15%" }}>Subject</TableCell>
              <TableCell sx={{ width: "10%" }}>Copy</TableCell>
              <TableCell sx={{ width: "15%" }}>Date</TableCell>
              <TableCell sx={{ width: "20%" }}>Note</TableCell>
              <TableCell sx={{ width: "10%" }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stockInputsTemp.map((e) => {
              if (e.id === currentRowEdit) {
                return (
                  <TableRow key={e.id}>
                    <TableCell>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="year-label">Year</InputLabel>
                        <Select
                          labelId="year-label"
                          id="year"
                          value={values.year}
                          onChange={(e) => {
                            setFieldValue("year", e.target.value);
                          }}
                          label="Year"
                          sx={{
                            height: 44,
                          }}
                        >
                          {YearHelper(currentYearTerms.year).map((e) => {
                            return (
                              <MenuItem key={e} value={e}>
                                {e}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </TableCell>

                    <TableCell>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="term-label">Term</InputLabel>
                        <Select
                          labelId="term-label"
                          id="term"
                          value={values.term}
                          onChange={(e) => {
                            setFieldValue("term", e.target.value);
                          }}
                          label="Term"
                          sx={{
                            height: 44,
                          }}
                        >
                          {Terms.map((e) => {
                            return (
                              <MenuItem key={e.value} value={e.value}>
                                {e.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </TableCell>

                    <TableCell>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="grade-label">Grade</InputLabel>
                        <Select
                          labelId="grade-label"
                          id="grade"
                          value={values.grade}
                          onChange={(e) => {
                            getSubjectByYear(e.target.value as number);
                            setFieldValue("grade", e.target.value);
                          }}
                          label="Grade"
                          sx={{
                            height: 44,
                          }}
                        >
                          {["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"].map(
                            (e) => {
                              return (
                                <MenuItem key={e} value={e}>
                                  {e}
                                </MenuItem>
                              );
                            }
                          )}
                        </Select>
                      </FormControl>
                    </TableCell>

                    <TableCell>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="subject-label">Subject</InputLabel>
                        <Select
                          labelId="subject-label"
                          id="subject"
                          value={values.ncSubjectId}
                          onChange={(e) => {
                            setFieldValue("ncSubjectId", e.target.value);
                            setFieldValue("subjectName", subjects.find(b=>b.id== e.target.value).subject);
                            console.log(e);
                          }}
                          label="Subject"
                          sx={{
                            height: 44,
                          }}
                        >
                          {subjects.map((e) => {
                            return (
                              <MenuItem key={e.id} value={e.id}>
                                {e.subject}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </TableCell>

                    <TableCell>
                      <FormField
                        value={values.copy}
                        type="number"
                        name="copy"
                        label="Copy"
                        variant="outlined"
                        onChange={(e) => {
                          setFieldValue("copy", e.target.value);
                        }}
                        //   error={errors.copy}
                      />
                    </TableCell>

                    <TableCell>
                      <MDDatePicker
                        value={values.date}
                        onChange={(e) => {
                          setFieldValue("date", e);
                        }}
                        label="Date"
                        input={{
                          label: "Date",
                          value: values.date ? dayjs(values.date).format("YYYY-MM-DD") : "",
                          fullWidth: true,
                          variant: "standard",
                        }}
                      />
                    </TableCell>

                    <TableCell>
                      <FormField
                        value={values.note}
                        name="note"
                        label="Note"
                        variant="outlined"
                        onChange={(e) => {
                          setFieldValue("note", e.target.value);
                        }}
                      />
                    </TableCell>

                    <TableCell>
                      <MDBox display="flex" gap={1}>
                        <MDButton onClick={handleSubmit} variant="gradient" color="info">
                          <CheckIcon />
                        </MDButton>
                        <MDButton
                          onClick={() => {
                            setCurrentRowEdit(null);
                          }}
                          variant="gradient"
                          color="warning"
                        >
                          <CloseIcon />
                        </MDButton>
                      </MDBox>
                    </TableCell>
                  </TableRow>
                );
              }

              return (
                <TableRow key={e.id}>
                  <TableCell>{e.year}</TableCell>
                  <TableCell>{e.term}</TableCell>
                  <TableCell>{e.grade}</TableCell>
                  <TableCell>{e.subjectName}</TableCell>
                  <TableCell>{e.copy}</TableCell>
                  <TableCell>{e.date ? dayjs(e.date).format("YYYY-MM-DD") : ""}</TableCell>
                  <TableCell>{e.note}</TableCell>
                  <TableCell>
                    <MDBox display="flex" gap={1}>
                      <MDButton
                        onClick={() => {
                          setCurrentRowEdit(e.id);
                          setValues(e);
                        }}
                        variant="gradient"
                        color="success"
                      >
                        <EditIcon />
                      </MDButton>
                      <MDButton
                        onClick={() => {
                          handleDelete(e.id);
                        }}
                        variant="gradient"
                        color="error"
                      >
                        <DeleteIcon />
                      </MDButton>
                    </MDBox>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </MDBox>

      <MDBox display="flex" justifyContent="end" pb={2} px={3}>
        <MDButton onClick={handleSave} variant="gradient" color="success">
          Save
        </MDButton>
      </MDBox>
    </>
  );
};

export default StockInputTable;

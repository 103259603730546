import { AxiosResponse } from "axios";
import { BookRequest } from "models/bookRequest";
import { BookResponse } from "models/bookResponse";
import { PagedResponseDto } from "models/pagedResponseDto";
import { ResponseModel } from "models/responseModel";
import { StateRequest } from "models/stateRequest";
import { StateResponse } from "models/stateResponse";
import { StatesFilterRequest } from "models/statesFilterRequest";
import { SubjectRequest } from "models/subjectRequest";
import { SubjectResponse } from "models/subjectResponse";
import { YearRequest } from "models/yearRequest";
import { YearResponse } from "models/yearResponse";
import api from "services/api";

const apiURLs = {
  states: "states",
  state: (stateId: number) => `states/${stateId}`,
  years: "years",
  year: (yearId: number) => `years/${yearId}`,
  subjects: "subjects",
  subject: (subjectId: number) => `subjects/${subjectId}`,
  books: "books",
  book: (bookId: number) => `books/${bookId}`,
};

const API = {
  updateState: (
    id: number,
    data: StateRequest
  ): Promise<AxiosResponse<ResponseModel<StateResponse>>> => api.put(apiURLs.state(id), data),
  deleteState: (id: number): Promise<AxiosResponse<ResponseModel<boolean>>> =>
    api.delete(apiURLs.state(id)),
  createState: (data: StateRequest): Promise<AxiosResponse<ResponseModel<StateResponse>>> =>
    api.post(apiURLs.states, data),
  getStates: (
    filters: StatesFilterRequest
  ): Promise<AxiosResponse<ResponseModel<PagedResponseDto<StateResponse>>>> =>
    api.get(apiURLs.states, { params: filters }),
  updateYear: (
    id: number,
    data: YearRequest
  ): Promise<AxiosResponse<ResponseModel<YearResponse>>> => api.put(apiURLs.year(id), data),
  deleteYear: (id: number): Promise<AxiosResponse<ResponseModel<boolean>>> =>
    api.delete(apiURLs.year(id)),
  createYear: (data: YearRequest): Promise<AxiosResponse<ResponseModel<YearResponse>>> =>
    api.post(apiURLs.years, data),
  updateSubject: (
    id: number,
    data: SubjectRequest
  ): Promise<AxiosResponse<ResponseModel<SubjectResponse>>> => api.put(apiURLs.subject(id), data),
  deleteSubject: (id: number): Promise<AxiosResponse<ResponseModel<boolean>>> =>
    api.delete(apiURLs.subject(id)),
  createSubject: (data: SubjectRequest): Promise<AxiosResponse<ResponseModel<SubjectResponse>>> =>
    api.post(apiURLs.subjects, data),
  updateBook: (
    id: number,
    data: BookRequest
  ): Promise<AxiosResponse<ResponseModel<BookResponse>>> => api.put(apiURLs.book(id), data),
  deleteBook: (id: number): Promise<AxiosResponse<ResponseModel<boolean>>> =>
    api.delete(apiURLs.book(id)),
  createBook: (data: BookRequest): Promise<AxiosResponse<ResponseModel<BookResponse>>> =>
    api.post(apiURLs.books, data),
};

export default API;

import { BookOrderRequest } from "models/bookOrderRequest";
import { PickupTimeBookingViewModal } from "models/pickupTimeBookingViewModal";
import api from "services/api";

export type GetBookOrderQueryParams = {
  status: string;
  year: number;
  term: string;
  branchCode: number;
};

const OrderGroupService = {
  getCurrentYearTerm: () => {
    return api.get(`/BookOrder/get-current-year-term`);
  },

  getBookOrder: (params: GetBookOrderQueryParams) => {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append("status", params.status);
    urlSearchParams.append("year", "" + params.year);
    urlSearchParams.append("term", params.term);
    urlSearchParams.append("branchCode", "" + params.branchCode);

    return api.get("/BookOrder?" + urlSearchParams.toString());
  },

  groupOrder: (ids: number[]) => {
    return api.post("/BookOrder", ids);
  },

  setStatusOrderGroup : (id:number,request:BookOrderRequest) =>{
    return api.put(`/BookOrder/${id}`,{status:request.status,backStatus:request.backStatus});
  },

  createDeliveryForm : (date:Date,id:number,branchName:string,branchCode:string) =>{
    return api.post('/DeliveryForms',{DateTime:date,GroupId:id,BranchName:branchName,BranchCode:branchCode})
  }

  
};

export default OrderGroupService;

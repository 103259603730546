import { useEffect, useMemo, useState } from "react";
import { ObjectTypes, StateItem } from "../configs/types";
import { toast } from "react-toastify";

interface Props {
  data?: StateItem;
  type: ObjectTypes;
  onSaveCell?: (
    value: string,
    type: ObjectTypes,
    data: StateItem,
    isUpdate: boolean,
    callback?: any
  ) => void;
  onDeleteCell?: (data: StateItem, type: ObjectTypes) => void;
  showAction?: boolean;
  showName?: boolean;
}

const useTableCellContent = (props: Props) => {
  const { data, type, showName, onSaveCell, showAction, onDeleteCell } = props;

  const [isEdit, setEdit] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleClick = () => {
    setEdit(true);
    setErrorMessage(null);
  };

  const handleClearData = () => {
    setEdit(false);
  };

  const handleCancel = () => {
    setEdit(false);
  };
  const handleChangeValue = (e: any) => {
    setValue(e.target.value);
    if (e.target.value?.trim()) {
      setErrorMessage(null);
    } else {
      setErrorMessage("Please enter your value");
    }
  };

  const handleSaveData = () => {
    if (errorMessage || !value?.trim()) {
      toast.error("Please enter your value");
      return;
    }
    onSaveCell?.(value, type, data, true, handleClearData);
  };

  const handleKeyPress = (e: any) => {
    if(e.key === "Enter") handleSaveData()
  }

  const handleAddCell = (value: string, callback?: any) => {
    onSaveCell?.(value, type, data, false, callback);
  };

  const handleDeleteCell = () => {
    onDeleteCell?.(data, type);
  };

  const name = useMemo(() => {
    return data?.[type.toLowerCase()];
  }, [type, JSON.stringify(data)]);

  useEffect(() => {
    setValue(name);
  }, [name]);

  const isShowContent = showName && !showAction && !!name;
  return {
    isEdit,
    value,
    isShowContent,
    errorMessage,
    name,
    handleDeleteCell,
    handleClick,
    handleCancel,
    handleChangeValue,
    handleSaveData,
    handleAddCell,
    handleKeyPress
  };
};

export default useTableCellContent;

import { useMaterialUIController } from "context";
import MDBox from "components/MDBox";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { NCBookOrder } from "models/nCBookOrder";

type Props = {
  data: NCBookOrder[];
  open: boolean;
  onClose: () => void;
};

const OrderGroupDetailDialog = ({ data, open, onClose }: Props) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const columns = [
    {
      field: "branchCode",
      headerName: "Branch code",
      width: 150,
    },
    {
      field: "categoryName",
      headerName: "Category",
      width: 150,
    },
    {
      field: "grade",
      headerName: "Grade",
      width: 100,
    },
    {
      field: "term",
      headerName: "Term",
      type: "number",
      width: 100,
    },
    {
      field: "year",
      headerName: "Year",
      type: "number",
      width: 100,
    },
    {
      field: "productName",
      headerName: "Product name",
      type: "number",
      width: 180,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      type: "number",
      width: 200,
    },
  ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Order group detail</DialogTitle>
      <DialogContent>
        <MDBox className={darkMode ? "ag-theme-alpine-dark" : "ag-theme-alpine"}>
          <AgGridReact rowData={data} columnDefs={columns} domLayout="autoHeight" />
        </MDBox>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrderGroupDetailDialog;

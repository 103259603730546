import { Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ClearIcon from '@mui/icons-material/Clear';
import { styled } from "@mui/system";
import { OrderGroupViewModel } from "models/bookOrder";
import dayjs from "dayjs";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { BookOrderRequest } from "models/bookOrderRequest";
const Container = styled("div")({
  border: "1px solid #9EC7FF",
  borderRadius: "4px",
  padding: "5px",
  width: "100%",
  display: "flex",
  alignItems: "center",
  gap: "10px",
  cursor: "pointer",
});

const Label = styled("div")({
  backgroundColor: "#F3F3F3",
  borderRadius: "4px",
  color: "#388CFF",
  display: "inline-block",
  padding: "4px 14px",
  fontSize: "14px",
});

const Flex = styled("div")({
  marginLeft: "10px",
  display: "flex",
  gap: "40px",
});

const ButtonSuccess = styled("button")({
  padding:"5px 20px",
  color:"white",
  background:"#0EB633",
  borderRadius:"4px",
  fontSize:"12px"
});

const ButtonLigth = styled("button")({
  padding:"2px 15px",
  color:"black",
  background:"#F6F6F6",
  borderRadius:"4px",
  fontSize:"13px",
  fontWeight:"600"
})

const DivInfo = styled("div")({
  padding:"2px 15px",
  color:"white",
  background:"#388CFF",
  borderRadius:"4px",
  fontSize:"12px"
})
type Props = {
  data: OrderGroupViewModel;
  setStatus : (id:number,request:BookOrderRequest) => void
  createDeliveryForm: (date:Date,id:number) => void
  onShowDetail: () => void
};

const DivPickupTime = styled("div")({
  padding:"6px 10px",
  color:"white",
  background:"#38A0FF",
  borderRadius:"4px",
  fontSize:"12px"
})

const LinkCustom = styled("div")({
  textDecoration:"underline",
  fontWeight:"500"
})



const OrderGroupItem = ({ data,setStatus, createDeliveryForm, onShowDetail }: Props) => {
  return (
    <Container >
      <Label>Order group</Label>
      <Flex>
        <Typography onClick={onShowDetail} display="flex" alignItems="center" color="#388CFF" fontSize={15}>
          <LinkCustom>ID: {data.groupId}</LinkCustom>
        </Typography>

        {data.status == "New" && data.isEnoughBooks ? (
          <Typography display="flex" alignItems="center" gap="5px" fontSize={15}>
            <MDButton onClick={() => setStatus(data.groupId,{status:1,backStatus:false})} color="success">
              Set ready for pickup
            </MDButton>
          </Typography>
        ) : null}

        {data.status == "ReadyForPickup" ? (
          <Typography display="flex" alignItems="center" gap="5px" fontSize={15}>
          {/* <ButtonLigth onClick={() => setStatus(data.groupId,{status:2,backStatus:false})}></ButtonLigth> */}
          <CheckCircleIcon fontSize="medium" color="success"/>Ready to pickup
          <button onClick={() => setStatus(data.groupId,{status:0,backStatus:true})}><ClearIcon/></button>
        </Typography>
        ) : null}
        
        {data.status == "PickupTimeScheduled" ? (
          <Typography display="flex" alignItems="center" gap="5px" fontSize={15}>
            <DivPickupTime>
              <p>
                {data.status} ({dayjs(data.dateTimePickup).format("YYYY-MM-DD")})
              </p>
              <p>
                Time:{dayjs(data.dateTimePickup).format("HH:mm")} - Location : {data.location}
              </p>
            </DivPickupTime>

            <button onClick={() => setStatus(data.groupId,{status:1,backStatus:true})}><ClearIcon/></button>
            <MDButton onClick={() => createDeliveryForm(new Date(),data.groupId)} color="success">
              Confirm delivery
            </MDButton>
          </Typography>
        ) : null}

        {data.status == "Delivered" ? (
          <Typography display="flex" alignItems="center" gap="5px" fontSize={15}>
            <CheckCircleIcon fontSize="medium" color="success"/>
            <strong>{data.status}</strong>
          </Typography>
        ) : null}
        <Typography display="flex" alignItems="center" fontSize={15}>
          <strong>Date:</strong> {dayjs(data.date).format("YYYY-MM-DD")}
        </Typography>
      </Flex>
    </Container>
  );
};

export default OrderGroupItem;

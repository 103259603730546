import MDBox from "components/MDBox";
import { styled } from "@mui/system";
import { DeliveryForm, NCBookOrderViewModel } from "models/bookOrder";
import { da } from "date-fns/locale";
import dayjs from "dayjs";

const Table = styled("table")({
    width:"100%",
    border:"1px solid #D7D7D7",
    overflowX:"auto"
  });

const Tr = styled("tr")({
    borderBottom:"1px solid #D7D7D7"
})

const Td = styled("td")({
    padding:"10px 20px",
    
})
const Span = styled("span")({
    fontWeight:600
})
type Props = {
    data: DeliveryForm;
};
const TableOrder = ({data}:Props) =>{
    return(
        !! data &&
        <MDBox>
            <MDBox bgColor="#388CFF" id="header-table-delivery">
                <MDBox>
                    <span style={{ color:"white" }}>ID: {data.id}</span>
                </MDBox>
                <MDBox>
                    <span style={{ color:"white",marginLeft:"20px" }}>Branches: {data.ncBookOrders[0].branchCode}</span>
                </MDBox>
            </MDBox>
            <MDBox>
                <Table>
                    <tbody>
                        {data && data.ncBookOrders && data.ncBookOrders.map((item)=>(
                            <Tr key={item.subjectId}>
                                <Td style={{ width:"20%" }}><Span>Created Time: {dayjs(data.dateTime).format("YYYY/MM/DD")}</Span></Td>
                                <Td><Span>Time: {dayjs(data.dateTime).format("HH:mm")}</Span> </Td>
                                <Td><Span>Grade:</Span> {item.grade}</Td>
                                <Td><Span>Subject:</Span> {item.productName}</Td>
                                <Td><Span>Copy:</Span> {item.quantity}</Td>
                            </Tr>
                        ))}
                    </tbody>
                </Table>
            </MDBox>
        </MDBox>
    );
}

export default TableOrder;
/* eslint-disable no-unused-vars */
import BaseLayout from "components/Customized/BaseLayout";
import "./style.css";
import CustomTable from "./components/CustomTable";
import useCreateOrder from "./hooks/useCreateOrder";
import { PAGE_SIZE_OPTIONS } from "./configs/constants";
import SearchInput from "components/Customized/SearchInput";

function CreateOrder() {
  const {
    data,
    filters,
    totalItems,
    searchText,
    handelChangeTextSearch,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSaveItem,
    handleDeleteItem,
  } = useCreateOrder();

  return (
    <BaseLayout>
      <div className="row mb-2">
        <div className="col-lg-6 col-12">
          <SearchInput fullWidth label="Search State" value={searchText} onChange={handelChangeTextSearch} />
        </div>
      </div>
      <CustomTable
        data={data}
        rowsPerPageOptions={PAGE_SIZE_OPTIONS}
        totalItems={totalItems}
        rowsPerPage={filters.pageSize}
        page={filters.currentPage - 1}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onSaveItem={handleSaveItem}
        onDeleteItem={handleDeleteItem}
      />
    </BaseLayout>
  );
}

export default CreateOrder;

import { styled } from "@mui/system";
import dayjs from "dayjs";
import MDBox from "components/MDBox";
import { TimeOption as ITimeOption } from "../useBooking";

const Container = styled("div")({
  padding: "15px",
  borderLeft: "1px solid #e8e8e8",
  borderTop: "none",
  backgroundColor: "white",
  height: "100%",
});

const TimeContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  justifyContent: "center",
  alignItems: "center",
  gap: "15px",
  marginTop: "15px",
});

interface TimeOptionProps {
  isActive: boolean;
}

const TimeOption = styled("div", {
  shouldForwardProp: (prop) => prop !== "isActive",
})<TimeOptionProps>(({ isActive }) => ({
  color: isActive ? "#fff" : "#388CFF",
  fontWeight: 600,
  backgroundColor: isActive ? "#388CFF" : "#fff",
  border: "1px solid #388CFF",
  borderRadius: "4px",
  width: "250px",
  height: "45px",
  lineHeight: "45px",
  textAlign: "center",
  fontSize: "16px",
  cursor: "pointer",
  transition: ".2s",
  "&:hover": {
    border: "1px solid #388CFF",
    backgroundColor: "#388CFF",
    color: "#fff",
    fontWeight: 600,
  },
}));

const Title = styled("p")({
  textAlign: "center",
  fontSize: "18px",
  fontWeight: 500,
  color: "#000",
});

type Props = {
  options: ITimeOption[];
  value: ITimeOption;
  onSelect: (date: { startTime: string; endTime: string }) => void;
  timeInfo: Array<{
    location: string;
    startTime: string;
    endTime: string;
  }>;
};

const TimeAvailable = ({ options, value, onSelect, timeInfo }: Props) => {
  return (
    <Container>
      <Title>{options.length > 0 ? "Time available" : "No Time Available"}</Title>

      <MDBox
        sx={{
          height: "440px",
          overflow: "auto",
        }}
      >
        {options.length > 0 && (
          <TimeContainer>
            {options.map((e) => {
              const label = `${dayjs(e.startTime).format("HH:mm")} - ${dayjs(e.endTime).format(
                "HH:mm"
              )}`;

              return (
                <TimeOption
                  isActive={e === value}
                  onClick={() => {
                    onSelect(e);
                  }}
                  key={label}
                >
                  {label}
                </TimeOption>
              );
            })}
          </TimeContainer>
        )}

        {timeInfo.map(({ location, startTime, endTime }) => {
          return (
            <div style={{marginTop:'2rem',textAlign:'center'}}>
              <h5>Location: {location}</h5>
              <MDBox mt={2}>
                <h5>
                  {dayjs(startTime).format("HH:mm")} - {dayjs(endTime).format("HH:mm")}
                </h5>
              </MDBox>
            </div>
          );
        })}

      </MDBox>
    </Container>
  );
};

export default TimeAvailable;

import { SyntheticEvent, useState } from "react";
import { styled } from "@mui/system";
import BaseLayout from "components/Customized/BaseLayout";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OrderItem from "./components/OrderItem";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import useOrderGroup from "./useOrderGroups";
import OrderGroupItem from "./components/OrderGroupItem";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import useModal from "hooks/useModal";
import OrderGroupDetailDialog from "./components/OrderGroupDetailDialog";
import { NCBookOrder } from "models/nCBookOrder";
import { Terms, YearHelper, Branch } from "constants/term";

const OrderContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  alignItems: "flex-end",
  maxHeight: "500px",
  overflow: "auto",
});

const OrderGroups = () => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const [dataDialog, setDataDialog] = useState<NCBookOrder[]>([]);
  const { open, toggle } = useModal();
  const {
    orders,
    orderGroups,
    handleSelectOrder,
    selectedIds,
    postGroupOrder,
    handleSetStatus,
    orderGroupStatus,
    setOrderGroupStatus,
    term,
    setTerm,
    year,
    setYear,
    branchCode,
    setBranchCode,
    handleCreateDelivery,
  } = useOrderGroup();

  const handleChange = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <BaseLayout>
    <OrderGroupDetailDialog data={dataDialog} open={open} onClose={toggle} />
      <MDBox display="flex" justifyContent="space-between" alignItems="center">
        <h4>Order groups</h4>

        <MDBox display="flex" gap={1}>
          <FormControl variant="outlined">
            <InputLabel id="year-label">Branch</InputLabel>
            <Select
              labelId="year-label"
              id="year"
              value={branchCode}
              onChange={(e) => {
                setBranchCode(e.target.value);
              }}
              label="Status"
              sx={{
                width: 200,
                height: 44,
              }}
            >
              {Branch.map((e) => {
                return (
                  <MenuItem key={e.branchCode} value={e.branchCode}>
                    {e.branchName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl variant="outlined">
            <InputLabel id="year-label">Status</InputLabel>
            <Select
              labelId="year-label"
              id="year"
              value={orderGroupStatus}
              onChange={(e) => {
                setOrderGroupStatus(e.target.value);
              }}
              label="Status"
              sx={{
                width: 200,
                height: 44,
              }}
            >
              <MenuItem value={"New"}>New</MenuItem>
              <MenuItem value={"ReadyForPickup"}>Ready For Pickup</MenuItem>
              <MenuItem value={"PickupTimeScheduled"}>Pickup Time Scheduled</MenuItem>
              <MenuItem value={"Delivered"}>Delivered</MenuItem>
            </Select>
          </FormControl>

          <FormControl variant="outlined">
            <InputLabel id="year-label">Year</InputLabel>
            <Select
              labelId="year-label"
              id="year"
              value={year}
              onChange={(e) => {
                setYear(e.target.value);
              }}
              label="Status"
              sx={{
                width: 120,
                height: 44,
              }}
            >
              {YearHelper(2024).map((e) => {
                return (
                  <MenuItem key={e} value={e}>
                    {e}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl variant="outlined">
            <InputLabel id="year-label">Term</InputLabel>
            <Select
              labelId="year-label"
              id="year"
              value={term.padStart(2, "0")}
              onChange={(e) => {
                setTerm(e.target.value);
              }}
              label="Status"
              sx={{
                width: 120,
                height: 44,
              }}
            >
              {Terms.map((e) => {
                return (
                  <MenuItem key={e.value} value={e.value}>
                    {e.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </MDBox>
      </MDBox>

      <Accordion expanded onChange={handleChange("panel1")}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>Branch {branchCode}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <OrderContainer>
            {orders.map((order) => {
              return (
                <OrderItem
                  selected={selectedIds.includes(order.orderDetailID)}
                  key={order.orderDetailID}
                  data={order}
                  onSelect={handleSelectOrder}
                />
              );
            })}
          </OrderContainer>

          <MDBox sx={{ my: 1, display: "flex", justifyContent: "space-between" }}>
            <MDButton disabled={selectedIds.length === 0} onClick={postGroupOrder} color="info">
              Group order
            </MDButton>
          </MDBox>

          <OrderContainer>
            {orderGroups.map((order) => {
              return (
                <OrderGroupItem
                  key={order.orderDetailID}
                  data={order}
                  setStatus={handleSetStatus}
                  createDeliveryForm={handleCreateDelivery}
                  onShowDetail={() => {
                    setDataDialog(order.bookOrders)
                    toggle();
                  }}
                />
              );
            })}
          </OrderContainer>
        </AccordionDetails>
      </Accordion>
    </BaseLayout>
  );
};

export default OrderGroups;

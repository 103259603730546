import React, { FC } from "react";
import TableCellAction from "./TableCellAction";
import { IconButton, TableCell, tableCellClasses } from "@mui/material";
import styled from "@emotion/styled";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import MDInput from "../../../components/MDInput";
import { ObjectTypes, StateItem } from "../configs/types";
import useTableCellContent from "../hooks/useTableCellContent";
import { useMaterialUIController } from "context";

const StyledTableCell = styled(TableCell)(({ theme }: any) => ({
  height: "1px",
  position: "relative",
  width: "25%",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const NameWrapper = styled("div")(({ theme, group, isEdit, darkMode }: any) => ({
  display: "flex",
  justifyContent: isEdit ? "center" : "space-between",
  alignItems: "center",
  flexWrap: "nowrap",
  flexDirection: isEdit ? "column" : "row",
  width: group ? "calc(100% - 4px)" : "unset",
  height: group ? "calc(100% - 8px)" : "",
  padding: group ? "0.75rem 1.5rem" : "",
  position: group ? "absolute" : "static",
  border: group ? "1px solid #f0f2f5" : "",
  borderLeftWidth: 0,
  top: group ? "4px" : "",
  left: group ? "0" : "",
  color: darkMode ? theme.palette.common.white : theme.palette.common.black
}));

interface Props {
  data?: StateItem;
  type: ObjectTypes;
  label?: string;
  rowSpan?: number;
  name?: string;
  actionLabel?: string;
  onSaveCell?: (value: string, type: ObjectTypes, data: StateItem, isUpdate: boolean, callback?: any) => void;
  onDeleteCell?: (data: StateItem, type: ObjectTypes) => void;
  showAction?: boolean;
  showName?: boolean;
  hidden?: boolean;
  group?: boolean;
  colSpan?: number;
  className?: string;
}

const TableCellContent: FC<Props> = ({
  data,
  type,
  label,
  rowSpan,
  showName,
  actionLabel,
  onSaveCell,
  showAction,
  hidden = false,
  group = true,
  colSpan,
  className = "",
  onDeleteCell
}) => {

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const {
    isEdit,
    value,
    isShowContent,
    errorMessage,
    name,
    handleDeleteCell,
    handleClick,
    handleCancel,
    handleChangeValue,
    handleSaveData,
    handleAddCell,
    handleKeyPress
  } = useTableCellContent({
    data,
    type,
    onDeleteCell,
    onSaveCell,
    showAction,
    showName
  })

  console.log({
    darkMode
  });
  

  return (
    <React.Fragment>
      {!hidden && (
        <StyledTableCell rowSpan={rowSpan} colSpan={colSpan} className={className}>
          {isShowContent && (
            <NameWrapper darkMode={darkMode} group={group} isEdit={isEdit}>
              {isEdit ? (
                <React.Fragment>
                  <div className="d-flex w-100">
                    <div className="flex-grow-1">
                      <MDInput
                        error={!!errorMessage}
                        className="w-100"
                        label={label}
                        placeholder="Enter your value"
                        value={value}
                        onChange={handleChangeValue}
                        onKeyPress={handleKeyPress}
                      />
                    </div>
                    <div className="d-flex align-items-center ml-2">
                      <IconButton color="primary" size="small" onClick={handleSaveData}>
                        <SaveIcon />
                      </IconButton>
                      <IconButton color="secondary" size="small" onClick={handleCancel}>
                        <CancelIcon />
                      </IconButton>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div>{name}</div>
                  <div>
                    <IconButton color="error" size="small" onClick={handleDeleteCell}>
                      <DeleteIcon />
                    </IconButton>
                    <IconButton color="warning" size="small" onClick={handleClick}>
                      <EditIcon />
                    </IconButton>
                  </div>
                </React.Fragment>
              )}
            </NameWrapper>
          )}

          {isEdit && isShowContent && group && (
            <div className="py-2 invisible">
              <MDInput className="w-100" />
            </div>
          )}
          {!isEdit && isShowContent && name && group && <div>{name}</div>}
          {showAction && (
            <TableCellAction actionLabel={actionLabel} label={label} onClick={handleAddCell} />
          )}
        </StyledTableCell>
      )}
    </React.Fragment>
  );
};

export default TableCellContent;

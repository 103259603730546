import styled from "@emotion/styled";
import { Button, IconButton } from "@mui/material";
import MDInput from "../../../components/MDInput";
import { FC, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { toast } from 'react-toastify';

const ActionButton = styled(Button)`
  text-transform: capitalize;
  font-size: 14px;
  margin: -1rem;
  padding-inline: 1rem;
`;

interface Props{
  onClick?: (value: string, callback?: any) => void
  label?: string
  actionLabel?: string
}

const TableCellAction:FC<Props> = ({ onClick, label, actionLabel }) => {
  const [isEdit, setEdit] = useState<boolean>(false);
  const [value, setValue] = useState<string>("")
  const [errorMessage, setErrorMessage] = useState<string>("")

  const handleKeyPress = (e: any) => {
    if(e.key === "Enter") handleSaveData()
  }

  const handleClearData = () => {
    setValue("");
    setEdit(false);
  }

  const handleClick = () => {
    setEdit(true);
    setErrorMessage(null)
  };
  const handleCancel = () => {
    setEdit(false)
  }
  const handleChangeValue = (e: any) => {
    setValue(e.target.value)
    if(e.target.value?.trim()){
      setErrorMessage(null)
    }else{
      setErrorMessage("Value is required")
    }
  }

  const handleSaveData = () => {
    if(errorMessage || !value?.trim()) {
      toast.error("Please enter your value")
      return
    }
    onClick?.(value, handleClearData)
  }

  return (
    <div>
      {!isEdit && (
        <ActionButton className="text-primary" onClick={handleClick}>
          <u>{actionLabel}</u>
        </ActionButton>
      )}
      {isEdit && (
        <div className="d-flex">
          <MDInput
            error={!!errorMessage}
            className="flex-grow-1"
            label={label}
            placeholder="Enter your value"
            value={value}
            onChange={handleChangeValue}
            onKeyPress={handleKeyPress}
          />
          <div className="d-flex align-items-center ml-2">
            <IconButton color="primary" size="small" onClick={handleSaveData}>
              <SaveIcon />
            </IconButton>
            <IconButton color="secondary" size="small" onClick={handleCancel}>
              <CancelIcon />
            </IconButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableCellAction;

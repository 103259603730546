import { useEffect, useState } from "react";
import {useNavigate, useParams} from "react-router-dom";
import SignaturePad from "signature_pad";
import DeliveryFormService from "./deliveryForm.service";
import { setAuth } from "stores/reducers/auth.reducer";
import PAGES from "navigation/pages";
import { store } from "stores";
import dayjs from "dayjs";

import { toast } from "react-toastify";
import { DeliveryForm, DeliveryFormUpdate, NCBookOrderViewModel } from "models/bookOrder";


const useDeliveryForm = () =>{
    const navigate = useNavigate();
    const [deliveryForm,setDeliveryForm] = useState<DeliveryForm>();
    const [signaturePad, setSignaturePad] = useState<SignaturePad>();
    const [signaturePad2, setSignaturePad2] = useState<SignaturePad>();
    const {key,id} = useParams();
    const { dispatch } = store;
    const [tokenAuth,setTokenAuth] = useState<string>();
    const getToken = async () =>{
        try {
            const response = await DeliveryFormService.getToken(dayjs(new Date().toJSON()).format("YYYY/MM/DD HH:mm:ss"))
            if(response){
                setTokenAuth(response.data.token)
                dispatch(setAuth({token : response.data.token}));
                navigate(PAGES.deliveryForm);
            }else{
                navigate(PAGES.signIn)
            }
                
        } catch (error) {
            console.log(error)
        }
    }
    const readyPad = (branchSignature,adminSignature) => {
        if(branchSignature && branchSignature.length > 0){
            branchSignature = branchSignature.filter(m=>m);
        }
        if(adminSignature && adminSignature.length > 0){
            adminSignature = adminSignature.filter(m=>m);
        }
        console.log('branchSignature',branchSignature);
        const wrapper = document.getElementById("signature-pad-1");
        const canvas = wrapper?.querySelector("canvas");
        canvas.getContext("2d").scale(1, 1);
        const tempSignaturePad = new SignaturePad(canvas, {
          backgroundColor: "rgb(255, 255, 255)",
        });
        setSignaturePad(tempSignaturePad);
        tempSignaturePad.fromData(branchSignature);

        const wrapper2 = document.getElementById("signature-pad-2");
        const canvas2 = wrapper2?.querySelector("canvas");
        canvas.getContext("2d").scale(1, 1);
        const tempSignaturePad2 = new SignaturePad(canvas2, {
          backgroundColor: "rgb(255, 255, 255)",
        });
        setSignaturePad2(tempSignaturePad2);
        tempSignaturePad2.fromData(adminSignature);
    };

    const GetDelivery = async() =>{
        try {
            const res = await DeliveryFormService.get(+id);
            setDeliveryForm(res?.data.data)
        } catch (error) {
            console.log(error);
        }
    }

    const handleUpdateDeliveryForm = async(id:number) =>{
        try {
            await DeliveryFormService.update(id,{
                DateTime : new Date(),
                BranchSignature : JSON.stringify(signaturePad.toData()),
                AdminSignature: JSON.stringify(signaturePad2.toData())
            });
            if(signaturePad && signaturePad2)
            {
                toast.success("Delivery Form Complete")
            }else{
                toast.success("Update Delivery Form Success");
            }
        } catch (error) {
            toast.error(error);
        }
    }
    const handleClearBranch = (event) => {
        event.preventDefault();
        signaturePad.clear();
    };

    const handleClearAdminSign = (event) =>{
        event.preventDefault();
        signaturePad2.clear();
    }

    useEffect(()=>{
        GetDelivery();
        if(key)
        {
            getToken();
        }
    },[tokenAuth]);

    useEffect(() => {
        readyPad(deliveryForm?.branchSignature ? JSON.parse(deliveryForm.branchSignature) : "",deliveryForm?.adminSignature ? JSON.parse(deliveryForm.adminSignature) : "");
    }, [deliveryForm]);


    return {
        handleClearBranch,
        handleClearAdminSign,
        handleUpdateDeliveryForm,
        deliveryForm
    };
}

export default useDeliveryForm;
import { TimeSlotViewModel } from "models/timeSlotViewModel";
import api from "services/api";

const DeliveryCalendarService = {
  getAll: ({ startTime, endTime }: { startTime: string; endTime: string }) => {
    return api.get(`/TimeSlot?startTime=${startTime}&endTime=${endTime}`);
  },

  create: (payload: TimeSlotViewModel) => {
    return api.post("/TimeSlot", payload);
  },

  update: (payload: TimeSlotViewModel) => {
    return api.put("/TimeSlot/" + payload.id, payload);
  },

  delete: (id: number) => {
    return api.delete("/TimeSlot/" + id);
  },
};

export default DeliveryCalendarService;

import { useEffect, useState } from "react";
import StockInputService from "../stockInput.service";
import { SubjectView } from "models/subjectView";
import { CurrentYearTerm } from "models/currentYearTerm";
import { StockInputViewModel } from "models/stockInputViewModel";

const useStockInput = () => {
  const [stockInputs, setStockInputs] = useState<StockInputViewModel[]>([]);
  const [subjects, setSubjects] = useState<SubjectView[]>([]);
  const [currentYearTerms, setCurrentYearTerms] = useState<CurrentYearTerm>(null);
  useEffect(() => {
    getStockInputs();
    getCurrentYearTerm();
  }, []);

  const getStockInputs = async () => {
    try {
      const res = await StockInputService.getAll({
        currentPage: 0,
        pageSize: 9999,
      });

      setStockInputs(res.data.items);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const createStockInputs = async (payload: StockInputViewModel[]) => {
    try {
      await StockInputService.create(payload);

      getStockInputs();
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const deleteStockInputs = async (id: number) => {
    try {
      await StockInputService.delete(id);

      getStockInputs();
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const getSubjectByYear = async (grade: number) => {
    try {
      const res = await StockInputService.getSubjectByYear(currentYearTerms.year, currentYearTerms.term, grade);
      console.log('res12',res)
      setSubjects(res.data);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const getCurrentYearTerm = async () => {
    try {
      const res = await StockInputService.getCurrentYearTerm();
      setCurrentYearTerms({year:res.data.year, term:res.data.termName})
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  return {
    subjects,
    stockInputs,
    createStockInputs,
    deleteStockInputs,
    getSubjectByYear,
    currentYearTerms
  };
};

export default useStockInput;

import { DeliverForm } from "models/deliverForm";
import { PickupTimeBooking } from "models/pickupTimeBooking";
import api from "services/api";

const DeliveryFormService = {
    get: (id: number) => {
        return api.get(`/DeliveryForms/${id}`);
    },
    getToken: (time:Date) =>{
        console.log(time)
        return api.get(`/Authentications/GetToken?time=${time}`)
    },

    update: (id:number,request:any) =>{
        return api.put(`/DeliveryForms/${id}`,request);
    },

    getBookOrdersByGroupId: (id:number) =>{
        return api.get(`/BookOrder/GetBookOrdersByGroupId/${id}`);
    }
}

export default DeliveryFormService;
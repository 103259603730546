import BaseLayout from "components/Customized/BaseLayout";
import MDBox from "components/MDBox";
import { styled } from "@mui/system";
import TableOrder from "./components/TableOrder";
import useDeliveryForm from "./useDeliveryForm";
import dayjs from "dayjs";
import QRCode from 'qrcode.react';

import {useParams} from "react-router-dom";
const Heading = styled("h3")({
    textAlign: "center",
    color: "black",
    fontSize: "26px",
    fontWeight: 600,
});

const ButtonSave = styled("button")({
    padding:"10px 20px",
    background:"#0EB633",
    borderRadius:"5px",
    color:"White"
})
const DeliveryForm = () =>{
    const {id} = useParams();
    const {handleClearBranch,handleClearAdminSign,handleUpdateDeliveryForm,deliveryForm} = useDeliveryForm();
    console.log('deliveryForm',deliveryForm);
    const isCheckSignature = (deliveryForm && (!deliveryForm.adminSignature || !deliveryForm.branchSignature));
    return(
        <BaseLayout>
            <MDBox mb={2} >
                <Heading>Delivery Form</Heading>
            </MDBox>
            <hr></hr>
            <h1 style={{ marginBlock:"20px" }}><span style={{ fontWeight:700 }}>Delivery Today: </span>{dayjs(new Date().toJSON()).format("YYYY/MM/DD HH:mm:ss")}</h1>
            <TableOrder data={deliveryForm}/>
            <MDBox className="signature-box">
                <MDBox id="signature-branch">
                    <label htmlFor="signature">Branch manager signs to confirm</label>
                    <MDBox id="signature-pad-1" style={{width:"fit-content"}} className="signature">
                        <canvas style={{pointerEvents:!isCheckSignature?"none":"unset"}} className="signature-canvas-branch"></canvas>
                    </MDBox>
                    <MDBox>
                        { isCheckSignature && <button onClick={handleClearBranch}>Clear</button>}
                    </MDBox> 
                </MDBox>

                <MDBox id="signature-admin">
                    <label htmlFor="signature">Admin signs to confirm</label>
                    <MDBox id="signature-pad-2" style={{width:"fit-content"}} className="signature">
                        <canvas style={{pointerEvents:!isCheckSignature?"none":"unset"}} className="signature-canvas-admin"></canvas>
                    </MDBox>
                    <MDBox>
                        {isCheckSignature && <button onClick={handleClearAdminSign}>Clear</button>}
                    </MDBox> 
                </MDBox>
            </MDBox>
            
            <MDBox id="box-qrcode-delivery-form">
                <QRCode
                    id='qrcode'
                    value={`${window.location.origin}/?id${id}=&key=${dayjs(new Date().toJSON()).format("HH:mm")}`}
                    size={190}
                    level={'H'}
                    includeMargin={false}
                    title="Scan QRCode"
                />
            </MDBox>
            {isCheckSignature && <ButtonSave onClick={() =>handleUpdateDeliveryForm(deliveryForm.id)}>Save</ButtonSave>}
        </BaseLayout>
    );
};

export default DeliveryForm;
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import dayjs from "dayjs";
import "../style.css";
import { useMaterialUIController } from "context";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
type Props = {
  stockOverviews: any;
};

const StockOverviewTable = (props: Props) => {
  const { stockOverviews } = props;
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [data, setData] = useState(stockOverviews);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  useEffect(() => {
    setData(stockOverviews);
  }, [stockOverviews])

  const handleSort = (column) => {
    let direction = "asc";
    if (sortConfig.key === column && sortConfig.direction === "asc") {
      direction = "desc";
    }
    const sortedData = [...data].sort((a, b) => {
      if (a[column] < b[column]) return -1;
      if (a[column] > b[column]) return 1;
      return 0;
    });

    if (direction === "desc") {
      sortedData.reverse();
    }
    setData(sortedData);
    setSortConfig({ key: column, direction });
  };
  const getSortIcon = (column) => {
    if (sortConfig.key === column) {
      return sortConfig.direction === "asc" ? <ArrowUpward /> : <ArrowDownward />;
    }
    return (
      <div>
        <ArrowUpward />
        <ArrowDownward />
      </div>
    );
  };

  return (
    <>
      <MDBox className={darkMode ? "ag-theme-alpine-dark" : "ag-theme-alpine"} pt={2} pb={2}>
        <div className="table-container-scroll">
          <Table
            className="table-bordered"
            sx={[{ minWidth: 650 }, { width: "100%" }]}
            aria-label="simple table"
          >
            <TableHead sx={{ display: "contents" }}>
              <TableRow>
                <TableCell
                  className="table-head-custom"
                  onClick={() => handleSort("grade")}
                  rowSpan={2}
                  sx={{ width: "10%" }}
                >
                  Year {getSortIcon("grade")}
                </TableCell>
                <TableCell
                  className="table-head-custom"
                  onClick={() => handleSort("subjectName")}
                  rowSpan={2}
                  sx={{ width: "10%" }}
                >
                  Subject {getSortIcon("subjectName")}
                </TableCell>
                <TableCell
                  className="table-head-custom"
                  onClick={() => handleSort("availableStock")}
                  rowSpan={2}
                  sx={{ width: "10%" }}
                >
                  Available Stock {getSortIcon("availableStock")}
                </TableCell>
                <TableCell
                  className="table-head-custom"
                  onClick={() => handleSort("waitingForPickup")}
                  rowSpan={2}
                  sx={{ width: "15%" }}
                >
                  Waiting for pickup stock {getSortIcon("waitingForPickup")}
                </TableCell>
                <TableCell
                  className="table-head-custom"
                  onClick={() => handleSort("totalDelivered")}
                  rowSpan={2}
                  sx={{ width: "10%" }}
                >
                  Total Delivered {getSortIcon("totalDelivered")}
                </TableCell>
                <TableCell
                  className="table-head-custom"
                  onClick={() => handleSort("totalOrdered")}
                  rowSpan={2}
                  sx={{ width: "15%" }}
                >
                  Total Ordered {getSortIcon("totalOrdered")}
                </TableCell>
                <TableCell
                  className="table-head-custom"
                  onClick={() => handleSort("orderNotReadyForPickup")}
                  rowSpan={2}
                  sx={{ width: "10%" }}
                >
                  Order Not Ready To Pick Up {getSortIcon("orderNotReadyForPickup")}
                </TableCell>
                {data.length > 0 &&
                  data[0].dailyViews.map((e) => {
                    return (
                      <TableCell
                        key={e.id}
                        className="table-head-custom"
                        colSpan={e.stockOrderByWeek.length}
                      >
                        Week {e.week}
                      </TableCell>
                    );
                  })}
              </TableRow>
              <TableRow>
                {data.length > 0 &&
                  data[0].dailyViews.map((daily) =>
                    daily.stockOrderByWeek.map((b, index) => (
                      <TableCell className="table-head-custom" key={index}>
                        {dayjs(b.date).format("DD/MM")}
                      </TableCell>
                    ))
                  )}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((e) => {
                return (
                  <TableRow key={e.subjectId}>
                    <TableCell>{e.grade}</TableCell>
                    <TableCell>{e.subjectName}</TableCell>
                    <TableCell>{e.availableStock}</TableCell>
                    <TableCell>{e.waitingForPickup}</TableCell>
                    <TableCell>{e.totalDelivered}</TableCell>
                    <TableCell>{e.totalOrdered}</TableCell>
                    <TableCell>{e.orderNotReadyForPickup}</TableCell>
                    {e.dailyViews.map((daily) =>
                      daily.stockOrderByWeek.map((b, index) => (
                        <TableCell key={index}>
                          {b.newStock}/{b.deliveredStock}
                        </TableCell>
                      ))
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </MDBox>
    </>
  );
};

export default StockOverviewTable;

import QrCodeDeliveryForm from "features/delivery-form/QrCodeDeliveryForm";
import SignIn from "features/sign-in/sign-in";
import PAGES from "navigation/pages";

const publicRoutes = [
  {
    exact: true,
    name: "Sign In",
    key: PAGES.signIn,
    route: PAGES.signIn,
    component: <SignIn />,
  },
  {
    exact: true,
    name: "Delivery Form",
    key: PAGES.qrDeliveryForm,
    route: PAGES.qrDeliveryForm,
    component: <QrCodeDeliveryForm />,
  },
];

export default publicRoutes;

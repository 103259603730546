import React, { FC, useCallback } from "react";
import { ObjectTypes, StateItem } from "../configs/types";
import TableCellContent from "./TableCellContent";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TablePagination } from "@mui/material";
import { useMaterialUIController } from "context";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTablePagination = styled(TablePagination)<{dark: boolean}>(({ dark }) => ({
  color: dark ? "#FFF" : "#000",
  width: "100%",
  border: "none",
  "& .Mui-disabled svg path": {
    fill: dark ? "#a0a0a0" : "",
  },
  "& button:not(.Mui-disabled) svg path": {
    fill: dark ? "#FFF" : "",
  }
}));

const StyledTableRow = styled(TableRow)(() => ({
  "& td, & th": {
    borderRight: "1px solid  #f0f2f5",
    paddingInline: "1.5rem",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    borderRight: "1px solid  #f0f2f5",
  },
}));

interface Props {
  data: StateItem[];
  rowsPerPageOptions: number[];
  totalItems: number;
  rowsPerPage: number;
  page: number;
  onChangePage: any;
  onChangeRowsPerPage: any;
  onSaveItem?: (
    value: string,
    type: ObjectTypes,
    data: StateItem,
    isUpdate: boolean,
    callback?: any
  ) => void;
  onDeleteItem?: (data: StateItem, type: ObjectTypes) => void;
}

const CustomTable: FC<Props> = ({
  data,
  rowsPerPageOptions,
  totalItems,
  rowsPerPage,
  page,
  onChangePage,
  onChangeRowsPerPage,
  onSaveItem,
  onDeleteItem,
}) => {

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const renderTableBody = useCallback(() => {
    return (
      <React.Fragment>
        {data.map((i, index) => {
          return (
            <React.Fragment key={index}>
              <StyledTableRow key={index}>
                <TableCellContent
                  data={i}
                  type="State"
                  label="State"
                  rowSpan={i.stateRows}
                  onSaveCell={onSaveItem}
                  onDeleteCell={onDeleteItem}
                  hidden={index > 0 && i.stateId === data[index - 1]?.stateId && !!i.stateId}
                  showName
                />
                <TableCellContent
                  data={i}
                  type="Year"
                  actionLabel={"+Add Year"}
                  label="Year"
                  rowSpan={i.yearRows}
                  showAction={!i.year && !!i.state}
                  onSaveCell={onSaveItem}
                  onDeleteCell={onDeleteItem}
                  hidden={index > 0 && i.yearId === data[index - 1]?.yearId && !!i.yearId}
                  showName
                />
                <TableCellContent
                  data={i}
                  type="Subject"
                  actionLabel={"+Add Subject"}
                  label="Subject"
                  rowSpan={i.subjectRows}
                  showAction={!i.subject && !!i.year}
                  onSaveCell={onSaveItem}
                  onDeleteCell={onDeleteItem}
                  hidden={index > 0 && i.subjectId === data[index - 1]?.subjectId && !!i.subjectId}
                  showName
                />
                <TableCellContent
                  data={i}
                  type="Book"
                  name={i.book}
                  actionLabel={"+Add Book"}
                  label="Book"
                  rowSpan={1}
                  showAction={!i.book && !!i.subject}
                  onSaveCell={onSaveItem}
                  onDeleteCell={onDeleteItem}
                  showName
                  group={false}
                />
              </StyledTableRow>

              {i.subjectId !== data[index + 1]?.subjectId && !!i.subjectId && !!i.book && (
                <StyledTableRow key={`${index}-book`}>
                  <TableCellContent
                    data={i}
                    type="Book"
                    actionLabel={"+Add Book"}
                    label="Book"
                    onSaveCell={onSaveItem}
                    showAction={true}
                  />
                </StyledTableRow>
              )}

              {i.yearId !== data[index + 1]?.yearId && !!i.year && !!i.subject && (
                <StyledTableRow key={`${index}-subject`}>
                  <TableCellContent
                    data={i}
                    type="Subject"
                    actionLabel={"+Add Subject"}
                    label="Subject"
                    onSaveCell={onSaveItem}
                    showAction={true}
                  />
                  <StyledTableCell></StyledTableCell>
                </StyledTableRow>
              )}

              {i.stateId !== data[index + 1]?.stateId && !!i.state && !!i.year && (
                <StyledTableRow key={`${index}-year`}>
                  <TableCellContent
                    data={i}
                    type="Year"
                    actionLabel={"+Add Year"}
                    label="Year"
                    onSaveCell={onSaveItem}
                    showAction={true}
                  />
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                </StyledTableRow>
              )}
            </React.Fragment>
          );
        })}
        <StyledTableRow key="state">
          <TableCellContent
            actionLabel={data.length === 0 ? "+Add State" : "+Add"}
            label="State"
            colSpan={data.length === 0 ? 4 : 1}
            showAction={true}
            onSaveCell={onSaveItem}
            className={`${data.length === 0 ? "text-center" : ""}`}
            type={"State"}
          />
          {data.length > 0 && (
            <React.Fragment>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </React.Fragment>
          )}
        </StyledTableRow>
      </React.Fragment>
    );
  }, [JSON.stringify(data)]);

  console.log({
    data,
  });

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead style={{ display: "table-header-group" }}>
          <TableRow>
            <StyledTableCell>State</StyledTableCell>
            <StyledTableCell>Year</StyledTableCell>
            <StyledTableCell>Subject</StyledTableCell>
            <StyledTableCell>Books</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {renderTableBody()}
          <tr>
            <StyledTablePagination
              dark={darkMode}
              colSpan={4}
              rowsPerPageOptions={rowsPerPageOptions}
              count={totalItems}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />
          </tr>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomTable;

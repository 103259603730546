import { useEffect, useState } from "react";
import StockOverviewService from "../stockOverview.service";

const useStockOverview = () => {
  const [stockOverviews, setStockOverviews] = useState([]);
  const [branchCodes, setBranchCode] = useState(0);

  useEffect(() => {
    if(branchCodes > 0){
      getStockOverview();
    }
  }, [branchCodes]);
  const getStockOverview = async () => {
    try {
      const res = await StockOverviewService.getAll(branchCodes);
      setStockOverviews(res.data);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  return {
    stockOverviews,
    setBranchCode,
  };
};

export default useStockOverview;
